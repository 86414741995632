import React, { useEffect, useState, useRef } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
 getMonitoreoPosts,
  chequeadorToggleForm,
  postMonitoreoAsociar,
  monitoreoSetLoadPublicationCardSelectedID,
  resetRegistroStatus,
  postVerificaionesNew,
  resetChequeadorVerificacionesNew,
  putEditVerificacion,
  verificacionesIsEdit, 
} from "src/store";

// Bootsrap and CSS
import { Row, Col } from "react-bootstrap";
import {
  StyledForm,
  StyledSidePanel,
  StyledSidePanelBackgroundChequeador,
  StyledSidePanelChequeador,
} from "../styled";
import "../style.css";
import { MdClose } from "react-icons/md";
import Select from "react-select";
import HistorialPush from "src/pages/Push/HistorialPush";

const FormSavePublication = (props) => {
  const selectInputRef = useRef();
  const [savingPublication, setSavingPublication] = useState(false);
  const history = useHistory();
  const [newVerificationTitle, setNewVerificationTitle] = useState("");
  const [newVerificacionId, setNewVerificacionId] = useState(null);

  // Message to be shown after Save action
  const [errorMessage, setErrorMessage] = useState(
    "Se produjo un error al guardar la publicación"
  );
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);

  const [cardSelected, setCardSelected] = useState(null);

  const handleInputChange = (e) => {
    setNewVerificationTitle(e.target.value);
  };

useEffect(() => {
  if (props.chequeador__verificiones__new) {
    const newVerificationId = props.chequeador__verificiones__new.id;
    if (newVerificationId) {
      history.push(`/chequeador/${newVerificationId}`);
      //props.resetChequeadorVerificacionesNew();
    }
  }
}, [props.chequeador__verificiones__new]);

  /* useEffect(() => {
    if (props.chequeador__verificiones__new !== null) {
      history.push(`/chequeador/${props.chequeador__verificiones__new}`);
    }
  }, [props.chequeador__verificiones__new, history]); */

  // Resto del código...

/*   const gotToForm = async () => {
    try {
      await props.postVerificaionesNew({ title: newVerificationTitle });
      history.push(`/chequeador/${props.chequeador__verificiones__new.id}`);
      
    } catch (error) {
      console.error("Error saving verification:", error);
    }
  }; */

  const gotToForm = async () => {
  try {
    await props.postVerificaionesNew({ title: newVerificationTitle });
    const newVerificationId = props.chequeador__verificiones__new.id;
    console.log("newVerificationId", newVerificationId);
    history.push(`/chequeador/${newVerificationId}`);
  } catch (error) {
    console.error("Error saving verification:", error);
  }
};
  // Function to save a publication into a card
  const savePublicationToExistingCard = async () => {
    // If we are doing a simple save form
    if (props.id === "save_publication") {
      // And there's NO card selected
      if (cardSelected === null || cardSelected === undefined) {
        // Set potential error message, and allow to display
        setErrorMessage("Seleccione una opción para poder guardar.");
        setShowFeedbackMessage(true);
        setSavingPublication(false);

        // Hide message after x time
        setTimeout(function () {
          setShowFeedbackMessage(false);
        }, 1000);
        // If there is card selected, then save the Publication to the Card
      } else {
        // Internal loading boolean
        setSavingPublication(true);

        // TODO: refactor this
        const contentToUpdate = props.monitoreo.results.find(
          (content) => content.id === props.idOfPublicationToSave
        );

        if (contentToUpdate) {
          const existingCards = (contentToUpdate.cards || []).map(
            (card) => card.id
          );
          const updatedCards = [...existingCards, cardSelected.value];

          console.log(updatedCards);
          await props.postMonitoreoAsociar(
            { cards: updatedCards },
            props.idOfPublicationToSave
          );
        } else {
          console.error("No se encontró el contenido con el ID especificado");
        }

        // Load error message (different from empty Card Selection)
        setErrorMessage("Se produjo un error al guardar la publicación.");
        setShowFeedbackMessage(true);
        setCardSelected([]);

        // After x time, hide message and close form
        setTimeout(function () {
          setSavingPublication(false);
          closeSaveForm();
          setShowFeedbackMessage(false);
        }, 1000);
      }
    } else if (props.id === "save_new_publication") {
      if (cardSelected != null && cardSelected != undefined) {
        await props.setLoadPublicationCardSelectedID(cardSelected.value);
        props.toggleForm(true, "create_publication", -1);
      }
    }
  };

  const closeSaveForm = () => {
    setCardSelected(null);
    props.toggleForm(false, props.id, -1);
  };

  const showForm = () => {
    return props.openForm === true;
  };

  return (
    <StyledSidePanelChequeador className={showForm() ? "show-form" : "hide"}>
      <StyledSidePanelBackgroundChequeador
        className={savingPublication ? "loading" : ""}
      >
        <Row>
          <Col lg={10}>
            <h1>{props.cardTitle}</h1>
            <p> {props.cardDescription} </p>
          </Col>
          <Col md={2}>
            <MdClose className="close-icon" onClick={closeSaveForm} />
          </Col>
        </Row>
        <StyledForm>
          <input
            type="text"
            className="text-container"
            placeholder="Titulo"
            value={newVerificationTitle}
            onChange={handleInputChange}
          />
          {showFeedbackMessage ? (
            props.registro_status === "error" ||
            props.registro_status === "idle" ? (
              <p className="error-message">{errorMessage}</p>
            ) : (
              <p className="success-message">
                La publicación fue guardada con éxito.
              </p>
            )
          ) : null}
          <div style={{ textAlign: "right" }}>
            <button className="primario" onClick={gotToForm}>
              Crear
            </button>
          </div>
        </StyledForm>
      </StyledSidePanelBackgroundChequeador>
    </StyledSidePanelChequeador>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    monitoreo: state.monitoreo,
    allCards: state.cards_monitoreo,
    openForm: state.chequeador__current_form_is_open,
    openFormId: state.chequeador__current_form_id,
    idOfPublicationToSave: state.chequeador__current_form_publication_id,
    registro_status: state.registro_status,
    cardTitle: ownProps.cardTitle,
    cardDescription: ownProps.cardDescription,
    id: ownProps.id,
    chequeador__verificiones__new: state.chequeador__verificiones__new,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleForm: (openForm, openFormId, publication_id) =>
      dispatch(chequeadorToggleForm(openForm, openFormId, publication_id)),
    getMonitoreoPosts: () => dispatch(getMonitoreoPosts()),
    postMonitoreoAsociar: (asociado, id) =>
      dispatch(postMonitoreoAsociar(asociado, id)),
    setLoadPublicationCardSelectedID: (cardSelectId) =>
      dispatch(monitoreoSetLoadPublicationCardSelectedID(cardSelectId)),
    resetRegistroStatus: () => dispatch(resetRegistroStatus()),
    postVerificaionesNew: (name) => dispatch(postVerificaionesNew(name)),

    resetChequeadorVerificacionesNew: () =>
      dispatch(resetChequeadorVerificacionesNew()),
    putEditVerificacion: (items) => dispatch(putEditVerificacion(items)),
    verificacionesIsEdit: () => dispatch(verificacionesIsEdit()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormSavePublication);
