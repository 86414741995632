import React, { useEffect, useState, useRef } from "react";

// Redux
import { connect } from "react-redux";
import {
   getMonitoreoPosts,
  monitoreoToggleForm,
  postMonitoreoAsociar,
  monitoreoSetLoadPublicationCardSelectedID,
  resetRegistroStatus, 
} from "src/store";

// Bootsrap and CSS
import { Row, Col } from "react-bootstrap";
import {
  StyledForm,
  StyledSidePanel,
  StyledSidePanelBackground,
} from "../styled";
import "../style.css";
import { MdClose } from "react-icons/md";
import Select from "react-select";

const FormSavePublication = (props) => {
  const selectInputRef = useRef();
  const [savingPublication, setSavingPublication] = useState(false);

  // Message to be shown after Save action
  const [errorMessage, setErrorMessage] = useState(
    "Se produjo un error al guardar la publicación"
  );
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);

  // Handler for the card selection
  const [cardsSelectOptions, setCardsSelectOptions] = useState([]);
  const [cardSelected, setCardSelected] = useState(null);

  // When the Form is opened, trigger an update action to get all cards
  useEffect(() => {
    if (props.openForm && props.openFormId === props.id) {
      // This whill update the "allCards" field
      props.getMonitoreoPosts();
    }
  }, [props.openForm, props.openFormId]);

  // When all cards are loaded, update the Select options with their value and label
  useEffect(() => {
    if (props.allCards) {
      var cardsOptions = props.allCards.results.map((el, index) => {
        return { value: el["id"], label: el["name"] };
      });
      setCardsSelectOptions(cardsOptions);
    }
  }, [props.allCards]);

  // This triggers after a correct register
  useEffect(() => {
    if (props.registro_status === "success" && savingPublication) {
      setTimeout(function () {
        setSavingPublication(false);
        console.log("RESET!!!");
        props.resetRegistroStatus();
      }, 1200);
    }
  }, [props.registro_status]);

  // Function to save a publication into a card
  const savePublicationToExistingCard = async () => {
    // If we are doing a simple save form
    if (props.id === "save_publication") {
      // And there's NO card selected
      if (cardSelected === null || cardSelected === undefined) {
        // Set potential error message, and allow to display
        setErrorMessage("Seleccione una opción para poder guardar.");
        setShowFeedbackMessage(true);
        setSavingPublication(false);

        // Hide message after x time
        setTimeout(function () {
          setShowFeedbackMessage(false);
        }, 1000);
        // If there is card selected, then save the Publication to the Card
      } else {
        // Internal loading boolean
        setSavingPublication(true);

        // TODO: refactor this
        const contentToUpdate = props.monitoreo.results.find(
          (content) => content.id === props.idOfPublicationToSave
        );

        if (contentToUpdate) {
          const existingCards = (contentToUpdate.cards || []).map(
            (card) => card.id
          );
          const updatedCards = [...existingCards, cardSelected.value];

            console.log(updatedCards);
          await props.postMonitoreoAsociar(
            { cards: updatedCards },
            props.idOfPublicationToSave
          );
        } else {
          console.error("No se encontró el contenido con el ID especificado");
        }

        // Load error message (different from empty Card Selection)
        setErrorMessage("Se produjo un error al guardar la publicación.");
        setShowFeedbackMessage(true);
        setCardSelected([]);

        // After x time, hide message and close form
        setTimeout(function () {
          setSavingPublication(false);
          closeSaveForm();
          setShowFeedbackMessage(false);
        }, 1000);
      }
    } else if (props.id === "save_new_publication") {
      // If we are selecting the card for a new Publication that
      // has not been created, dispatch the action to update the
      // monitoreo__load_publication_card_selected_id with the ID
      // of the card selected here. Then toggle the Form to upload
      // the publication.
      if (cardSelected != null && cardSelected != undefined) {
        await props.setLoadPublicationCardSelectedID(cardSelected.value);
        props.toggleForm(true, "create_publication", -1);
      }
    }
  };

  // Open the Create new card Form
  const openCreateNewCardForm = () => {
    // Close this form
    closeSaveForm();

    // If this is normal save, just open create form
    if (props.id === "save_publication") {
      props.toggleForm(true, "create_card", props.idOfPublicationToSave);
    }
    // If is for new publication not created yet, then open special create form
    else if (props.id === "save_new_publication") {
      props.toggleForm(true, "create_card_for_new_publication", -1);
    }
  };

  // Close this form
  const closeSaveForm = () => {
    setCardSelected(null);
    props.toggleForm(false, props.id, -1);
  };

  // If the boolean openForm is true and we are the ID of the
  // openFormId then show, if not, hide
  const showForm = () => {
    return props.openForm && props.openFormId === props.id;
  };

  useEffect(() => {
    if (
      !props.monitoreo__current_form_is_open &&
      props.monitoreo__current_form_publication_id == "-1"
    ) {
      setCardSelected(null);
    }
  }, [
    props.openForm,
    props.monitoreo__current_form_is_open,
    props.monitoreo__current_form_publication_id,
  ]);

  console.log("props.openForm", props.openForm);
  return (
    <StyledSidePanel className={showForm() ? "show-form" : "hide"}>
      <StyledSidePanelBackground className={savingPublication ? "loading" : ""}>
        <Row>
          <Col lg={10}>
            <h1>{props.cardTitle}</h1>
            <p> {props.cardDescription} </p>
          </Col>
          <Col md={2}>
            <MdClose className="close-icon" onClick={closeSaveForm} />
          </Col>
        </Row>
        <StyledForm>
          <Select
            options={cardsSelectOptions}
            onChange={(choice) => setCardSelected(choice)}
            value={cardSelected || " "}
            placeholder="Escribí para buscar entre las tarjetas existentes"
            className="select-container"
            classNamePrefix="select-container"
            maxMenuHeight="200px"
          />
          {showFeedbackMessage ? (
            props.registro_status === "error" ||
            props.registro_status === "idle" ? (
              <p className="error-message">{errorMessage}</p>
            ) : (
              <p className="success-message">
                La publicación fue guardada con éxito.
              </p>
            )
          ) : null}
          <button onClick={savePublicationToExistingCard} className="primario">
            Guardar
          </button>
          <hr />
          <p>¿No hay Tarjeta creada para esta publicación?</p>
          <button onClick={openCreateNewCardForm} className="primario">
            Crear nueva Tarjeta
          </button>
        </StyledForm>
      </StyledSidePanelBackground>
    </StyledSidePanel>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    monitoreo: state.monitoreo,
    allCards: state.cards_monitoreo,
    openForm: state.monitoreo__current_form_is_open,
    openFormId: state.monitoreo__current_form_id,
    idOfPublicationToSave: state.monitoreo__current_form_publication_id,
    registro_status: state.registro_status,
    cardTitle: ownProps.cardTitle,
    cardDescription: ownProps.cardDescription,
    id: ownProps.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleForm: (openForm, openFormId, publication_id) =>
      dispatch(monitoreoToggleForm(openForm, openFormId, publication_id)),
    getMonitoreoPosts: () => dispatch(getMonitoreoPosts()),
    postMonitoreoAsociar: (asociado, id) =>
      dispatch(postMonitoreoAsociar(asociado, id)),
    setLoadPublicationCardSelectedID: (cardSelectId) =>
      dispatch(monitoreoSetLoadPublicationCardSelectedID(cardSelectId)),
    resetRegistroStatus: () => dispatch(resetRegistroStatus()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormSavePublication);
