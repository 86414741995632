import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { BsSearch } from "react-icons/bs";
import { connect } from "react-redux";
import { StyledForm, StyleWrapperForm, StyledFormSearch } from "./styled";
import { MdClose } from "react-icons/md";
import { getCardsRequestDefault, getDays, getQuery, getMedia, getPage, 
  getFilterFlag, 
  getQueryName, getQueryTitle } from "src/store";

function Filter(props) {
  const [loading, setloading] = useState(true);
  const [selectFilterStatus, setSelectFilterStatus] = useState(false);

  const [selectFilterStatusSource, setSelectFilterStatusSource] = useState(
    false
  );
  const [selectFilterSource, setSelectFilterSource] = useState([]);

  const [selectFilterStatusMedia, setSelectFilterStatusMedia] = useState(false);

  const [selectFilterDays, setSelectFilterDays] = useState("");

  const [lastSelected, setlastselected] = useState("Todos");
  const [lastDaySelected, setlastDayselected] = useState("3");


  const [querySearch, setquerySearch] = useState("");
  const [querySearchName, setquerySearchName] = useState("");
  const [querySearchTitle, setquerySearchTitle] = useState("");

  useEffect(() => {
    try {
      if (props.filter && Object.keys(props.filter).length !== 0) {
        setloading(false);
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.filter]);

  const handleFilterChangeSource = (event) => {
    setSelectFilterStatusSource(true);
    if (event.target && event.target.value === "Todos") {
      setlastselected(event.target.value);
      setSelectFilterSource([]);
      return null;
    }
    if (event.target && event.target.value) {
      setSelectFilterSource([...selectFilterSource, event.target.value]);
      props.getMedia([...selectFilterSource, event.target.value])
      setlastselected(event.target.value);
    }
  };

  const handleFilterChangeDays = (event) => {
    props.getDays(event.target.value)
    setSelectFilterStatus(true);
    if (event.target && event.target.value) {
      setlastDayselected(event.target.value);
      setSelectFilterDays(event.target.value);
    }
  };

  const handleRemoveFilterDays = (e) => {
    setSelectFilterStatus(false);
    props.getDays(3)
    setSelectFilterDays("");
    setlastDayselected("3");
  };

  const handleRemoveFilterSource = (e) => {
    const res = selectFilterSource.filter((el) => el !== e);
    props.getMedia(res.length > 0 ? res : 'all')
    setSelectFilterStatusSource(false);
    setSelectFilterSource(res);
    setlastselected("Todos");
  };

  const handleRemoveAllFilters = (days) => {
    setSelectFilterStatusMedia(false);
    setSelectFilterStatusSource(false);
    setSelectFilterStatus(false);
    setSelectFilterSource([]);
    setlastselected("Todos");
    setSelectFilterDays("");
    setlastDayselected("3");
    props.getQuery(null)
    props.getMedia("all")
    props.getFilterFlag()
    props.getPage(1)
    props.getDays(3)
    props.getQueryName(null)
    props.getQueryTitle(null)
    setquerySearch("");
    setquerySearchName("");
    setquerySearchTitle("")
    props.getCardsRequestDefault({
      media_source : "all", days: props.days, order: "-relevance_score", page: props.page, category: props.category != null ? props.category : null, search: null, speaker: null,
      title: null
    });
  };

  const handleFilterQueryRequest = (e) => {
    e.preventDefault();
    props.getQuery(e.target.value)
    setquerySearch(e.target.value);
  };

  const handleFilterFrase = (e) => {
    e.preventDefault();
    props.getCardsRequestDefault({
      media_source: selectFilterSource.length > 0 ? selectFilterSource : props.media_source,
      days: props.days,
      order: props.order,
      page: props.page,
      search: querySearch.length > 0 ? props.search: null,
      category: props.category,
      speaker: querySearchName.length > 0 ? props.search_name : null,
      title: querySearchTitle.length > 0 ? props.search_title : null
    });
  }

  const handleFilterNameRequest = (e) => {
    e.preventDefault();
    props.getQueryName(e.target.value)
    setquerySearchName(e.target.value);
  };


  const handleFilterName = (e) => {
    e.preventDefault();
    props.getCardsRequestDefault({
      media_source: selectFilterSource.length > 0 ? selectFilterSource : props.media_source,
      days: props.days,
      order: props.order,
      page: props.page,
      search: querySearch.length > 0 ? props.search: null,
      category: props.category,
      speaker: querySearchName.length > 0 ? props.search_name : null,
      title: querySearchTitle.length > 0 ? props.search_title : null
    }); 
  }

  const handleFilterTitleRequest = (e) => {
    e.preventDefault();
    props.getQueryTitle(e.target.value)
    setquerySearchTitle(e.target.value);
  };


  const handleFilterTitle = (e) => {
    e.preventDefault();
    props.getCardsRequestDefault({
      media_source: selectFilterSource.length > 0 ? selectFilterSource : props.media_source,
      days: props.days,
      order: props.order,
      page: props.page,
      search: querySearch.length > 0 ? props.search: null,
      category: props.category,
      speaker: querySearchName.length > 0 ? props.search_name : null,
      title: querySearchTitle.length > 0 ? props.search_title : null
    });
  }

  const handleFilterRequest = (e) => {
    e.preventDefault();
    props.getFilterFlag()
    props.getPage(1)
    props.getCardsRequestDefault({
      media_source: selectFilterSource.length > 0 ? selectFilterSource : props.media_source,
      days: props.days,
      order: props.order,
      page: props.page,
      search: querySearch.length > 0 ? querySearch: null,
      category: props.category,
      speaker: querySearchName.length > 0 ? props.search_name: null,
      title: querySearchTitle.length > 0 ? props.search_title : null
    });
  };

  return (
    <Row style={{ paddingTop: "30px" }}>
      <Col lg={12}>
        <Row style={{ marginBottom: "30px" }}>
          <Col lg={4}>
            <strong style={{ fontSize: "16px" }}>Buscar</strong>
          </Col>
          <Col lg={8} className="text-right my-auto">
            {/* <p style={{ fontSize: "12px", marginBottom: "0" }}>
              23/06/21 11:43hs
            </p> */}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <StyledFormSearch onSubmit={handleFilterFrase}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Dentro de la frase
                </Form.Label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={querySearch}
                    onChange={handleFilterQueryRequest}
                  />
                  <span
                    className="input-group-btn"
                    style={{ backgroundColor: "#FF3370" }}
                  >
                    <button
                      className="btn btn-default"
                      type="submit"
                      style={{ color: "#fff", padding: "2px 13px" }}
                    >
                      <BsSearch />
                    </button>
                  </span>
                </div>
              </Form.Group>
            </StyledFormSearch>
            {/* <StyledFormSearch onSubmit={handleFilterName}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Por nombre y/o apellido
                </Form.Label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={querySearchName}
                    onChange={handleFilterNameRequest}
                  />
                  <span
                    className="input-group-btn"
                    style={{ backgroundColor: "#FF3370" }}
                  >
                    <button
                      className="btn btn-default"
                      type="submit"
                      style={{ color: "#fff", padding: "2px 13px" }}
                    >
                      <BsSearch />
                    </button>
                  </span>
                </div>
              </Form.Group>
            </StyledFormSearch> */}
            <StyledFormSearch onSubmit={handleFilterTitle}>
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Por título
                </Form.Label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={querySearchTitle}
                    onChange={handleFilterTitleRequest}
                  />
                  <span
                    className="input-group-btn"
                    style={{ backgroundColor: "#FF3370" }}
                  >
                    <button
                      className="btn btn-default"
                      type="submit"
                      style={{ color: "#fff", padding: "2px 13px" }}
                    >
                      <BsSearch />
                    </button>
                  </span>
                </div>
              </Form.Group>
            </StyledFormSearch>
            <StyleWrapperForm>
              <StyledForm onSubmit={handleFilterRequest}>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <div className="filterArea">
                    {selectFilterStatus ? (
                      <p>
                        {selectFilterDays}
                        <span>
                          <MdClose onClick={handleRemoveFilterDays} />
                        </span>
                      </p>
                    ) : null}
                    {!!selectFilterSource.length &&
                      selectFilterSource.map((el, index) => {
                        return (
                          <p key={index}>
                            {el}
                            <span>
                              <MdClose
                                onClick={() => handleRemoveFilterSource(el)}
                              />
                            </span>
                          </p>
                        );
                      })}
                  </div>
                  <Form.Label>Días</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    onChange={handleFilterChangeDays}
                    value={lastDaySelected}
                  >
                    {!loading
                      ? props.filter.days.map((el, index) => (
                          <option key={index} value={el}>
                            {el === 1 ? "Último día" : `Últimos ${el} días`}
                          </option>
                        ))
                      : null}
                  </Form.Control>
                  <Form.Label>Medios</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    onChange={handleFilterChangeSource}
                    value={lastSelected}
                    data-dropup-auto="false"
                  >
                    <option value="Todos">Todos</option>
                    {!loading
                      ? props.filter.media_source.map((el, index) => (
                          <option key={index} value={el}>
                            {el}
                          </option>
                        ))
                      : null}
                  </Form.Control>
                </Form.Group>
                <Button
                  type="submit"
                  size="lg"
                  style={{
                    fontSize: "16px",
                    width: "100%",
                    background: "#354052",
                    border: "none",
                    fontWeight: "bold",
                    marginBottom: "12px",
                  }}
                >
                  Filtrar
                </Button>
              </StyledForm>
              <Button
                type="submit"
                size="lg"
                onClick={() => handleRemoveAllFilters()}
                className="clean-button"
              >
                LIMPIAR FILTROS
              </Button>
            </StyleWrapperForm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    filter: state.filter,
    days: state.days,
    media_source: state.media_source,
    order: state.order,
    page: state.page,
    search: state.search,
    category: state.category,
    search_name: state.search_name,
    search_title: state.search_title
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCardsRequestDefault: (arg) => dispatch(getCardsRequestDefault(arg)),
    getDays: days => dispatch(getDays(days)),
    getQuery: search => dispatch(getQuery(search)),
    getMedia: media => dispatch(getMedia(media)),
    getPage: page => dispatch(getPage(page)),
    getFilterFlag: () => dispatch(getFilterFlag()),
    getQueryName: value => dispatch(getQueryName(value)),
    getQueryTitle: value => dispatch(getQueryTitle(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
