import React, { useState, useEffect } from "react";
// Boostrap and CSS
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import {
  StyledContainerFilters,
  StyledContainerCardGuardados,
} from "../styled";
import { FaSearch, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { MdClose, MdFilterAltOff, } from "react-icons/md";

// Components
import LoadingCard from "src/components/LoadingCard";
import ReactPaginate from "react-paginate";
import SavedCardPreview from "src/components/Monitoreo/SavedCardPreview";
import NoEncontrado from "src/components/no-encontrado";
import Select from "react-select";
import { FiDownload } from "react-icons/fi";

// Redux
import { connect } from "react-redux";
import {
  getRegistrosPage,
  //getMonitoreoCategoriasFormSelecTicket,
} from "src/store";

// Utils date
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs"; // Importación básica de Day.js
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.locale("es");





const Guardados = (props) => {


  const amountOfCardsPerPage = 12;

  const [data, setData] = useState();

  const [orden, setOrden] = useState("Todos");
  const [estado, setEstado] = useState("Todos");
  const [selectedSubtopics, setSelectedSubtopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [querySearch, setQuerySearch] = useState("");

  const initialSelectValues = {
    orden: "-created_at",
    status: "Todos"
  };

  const buildApiUrl = (selectedPage) => {
    let url = "";
    if (selectedPage !== undefined) {
      const offset = selectedPage === 0 ? 0 : (selectedPage - 1) * amountOfCardsPerPage;
      url += `${offset}`;
      return url
    } else {
      const defaultPage = 1;
      const offset = (defaultPage - 1) * amountOfCardsPerPage;
      url += `${offset}`;
      return url
    }
  };

  const fetchMonitoreoGuardados = async (selectedPage) => {
    try {
      if (props.login) {
        const redesUrlParams = buildApiUrl(selectedPage);
       /*  const tagsParam =
          selectedCategory === "Categorias" || selectedCategory === "Todos" ? "" : selectedCategory; */
        const requestParams = {
          ordering: orden === "Todos" ? "" : orden,
          status: estado === "Todos" ? "" : estado,
         // tags: tagsParam,
          search: querySearch,
          page: redesUrlParams == "" ? "" : redesUrlParams,
          limit: amountOfCardsPerPage,
          subtopics: selectedSubtopics.length == 0 ? [] : selectedSubtopics
        };
        await props.getRegistrosPage(requestParams);

       // await props.getMonitoreoCategoriasFormSelecTicket();
      }
    } catch (error) {
      console.log("Error al cargar las cards:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (props.login) {
          await fetchMonitoreoGuardados();
        }
      } catch (error) {
        console.log("Error al cargar las cards:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.login, estado, orden, props.registro_status, selectedSubtopics]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (props.login) {
          await fetchMonitoreoGuardados();
        }
      } catch (error) {
        console.log("Error al cargar las cards:", error);
      } finally {
        setLoading(false);
      }
    };

    if (querySearch === "")
      fetchData();
  }, [querySearch]);


  useEffect(() => {
    if (
      props.page_registrados.results &&
      props.page_registrados.results.length >= 0
    ) {
      setData(props.page_registrados.results);
    } else{
      setData(null);
    }
    setLoading(false);
  }, [props.page_registrados]);

  useEffect(() => {
    try {
      if (props.monitoreo_id && Object.keys(props.monitoreo_id).length != 0) {
        setLoading(false);
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.monitoreo_id]);

  const handleFilterQueryRequest = (e) => {
    //e.preventDefault();
    setQuerySearch(e.target.value);
  };

  const handleKeyDownGuardados = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      fetchMonitoreoGuardados();
      // props.getMonitoreoGuardadosSearch(querySearch);
    }
  };

  const handleFilterOrden = (event) => {
    setOrden(event.target.value);
  };

  const handleFilterEstado = (event) => {
    setEstado(event.target.value === "Todos" ? "" : event.target.value);
  };


  const handleClearInput = () => {
    setOrden(initialSelectValues.orden);
    setEstado(initialSelectValues.status);
   // setSelectedCategory("");
    // Automatically it fetches data again
  };

  const handleClearSearchQuery = () => {
    setQuerySearch("");
  }

  // For select fields we run a special version,
  // as the name comes in a separate parameter
  function handleTopicsSelectChange(event, action) {
    var selectedTopics = event;
    var subtopics = [];
    
    // if (selectedTopics.length == 0)
    //   selectedTopics = props.topics;

    for(var topicIdx in selectedTopics){
      var topic = selectedTopics[topicIdx];
      subtopics = subtopics.concat(topic.subtopics.map((item) => item.id));
    }

    setSelectedSubtopics(subtopics);
  }

  const parsedTopics = (topics) => {
    let parsedTopics = topics.map((item) => ({
      value: item.id, 
      label: item.name, 
      subtopics: item.subtopics
    }))
    return parsedTopics;
  }

/*   const handleCategorias = (event) => {
    console.log(event.target.value);
    setSelectedCategory(event.target.value);
  }; */

  const handlePageChange = (selectedPage) => {
    setLoading(true);
    fetchMonitoreoGuardados(selectedPage + 1)
  }




const createCSVFile = (csvContent) => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  return URL.createObjectURL(blob);
};

const handleDownload = async () => {
  try {
    const response = await fetch("https://admin.chequeabot.com/monitor/api/v1/cards/?export_csv=true", {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob(); // Obtiene el archivo como un Blob
    const url = URL.createObjectURL(blob); // Crea un URL para el Blob

    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.xlsx'; // Establece el nombre del archivo con extensión .xlsx
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("There was an error downloading the XLSX file:", error);
  }
};




  return (
    <Row>
      <Col md={10}>
        <Container fluid>
          <Row>
            <Col sm={12} md={8}>
              <Row>
                <Col lg={12} className="mx-auto">
                  <div className="input-group search-bar">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FaSearch style={{ fontSize: "16px" }} />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Búsqueda en título y descripción de Tarjetas"
                      value={querySearch}
                      onChange={handleFilterQueryRequest}
                      onKeyDown={handleKeyDownGuardados}
                    />
                    {querySearch && (
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          style={{
                            backgroundColor: "#e9ecef",
                            borderRadius: "0px 0px 0px 0px",
                            cursor: "pointer",
                          }}
                          onClick={handleClearSearchQuery}
                        >
                          <MdClose
                            style={{ fontSize: "16px", color: " #212529" }}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
             <Col sm={12} md={4} style={{display: "ruby"}}>
              <button
                className="button-cargar"
                style={{
                }}
                onClick={() => handleDownload()}
              >
                <FiDownload />
                Descargar datos
              </button>
              
            </Col>
          </Row>

        </Container>
        <StyledContainerFilters
          style={{ marginBottom: "34px" }}
          fluid
          className="scroll-container"
        >
          <Row>
            <Col lg={12}>
              <Row style={{ justifyContent: "space-between" }}>
                <Col lg={8}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: "15px", paddingRight: "15px", borderRight: "1px solid #354052" }}>
                      <p
                        style={{
                          marginBottom: "0",
                          fontSize: "14px",
                          marginLeft: "4px",
                        }}
                      >
                        Orden
                      </p>
                      <select
                        id="orden"
                        onChange={handleFilterOrden}
                        value={orden}
                      >
                        <option value="-created_at">Mas actuales</option>
                        <option value="created_at">Mas antiguos</option>
                      </select>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <p
                        style={{
                          marginBottom: "0",
                          fontSize: "14px",
                          marginLeft: "4px",
                        }}
                      >
                        Estado
                      </p>
                      <select
                        id="register"
                        onChange={handleFilterEstado}
                        value={estado}
                      >
                        <option value="Todos">Todos</option>
                        <option value="done">Chequeado</option>
                        <option value="pending">Falta chequear</option>
                        <option value="in_progress">En proceso</option>
                        <option value="cancelled">No se chequea</option>
                      </select>
                    </div>
                    <div style={{ marginRight: "10px" }}>
                      <p
                        style={{
                          marginBottom: "0",
                          fontSize: "14px",
                          marginLeft: "4px",
                        }}
                      >
                        Temas
                      </p>
                      
                      <Select
                        className="select-filter-container"
                        classNamePrefix="select-filter-container"
                        placeholder="Temas"
                        options={parsedTopics(props.topics)}
                        //getOptionLabel={(option) => option.name}
                        //getOptionValue={(option) => option.id}
                        name="filtro-temas"
                        onChange={handleTopicsSelectChange}
                        isMulti
                      />

                    </div> 
                    <button
                      onClick={handleClearInput}
                      className="limpiar-filtros"
                    >
                      <MdFilterAltOff />
                      Restablecer
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </StyledContainerFilters>
        <StyledContainerCardGuardados
          fluid
          className="scroll-container"
          style={{ paddingRight: "30px" }}
        >
          <Row>
            {data == null || data == undefined || loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingCard />
              </div>
            ) : data !== undefined && data !== null && data.length > 0 ? (
              data?.map((el, index) => (
                <SavedCardPreview el={el} index={index} key={index} />
              ))
            ) : (
              <NoEncontrado />
            )}
            {data !== undefined && data !== null 
              && props.page_registrados.count > amountOfCardsPerPage ? (
              <ReactPaginate
                previousLabel={<FaArrowLeft />}
                nextLabel={<FaArrowRight />}
                pageCount={props.page_registrados.count / amountOfCardsPerPage}
                onPageChange={(selectedPage) =>
                  handlePageChange(selectedPage.selected)
                }
                breakLabel={"..."}
                breakClassName={"break-me"}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            ) : null}

          </Row>
        </StyledContainerCardGuardados>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    monitoreo: state.monitoreo,
    loading: state.loading,
    login: state.login,
    error: state.error,
    monitoreo_id: state.monitoreo_id,
    categorias_form: state.categorias_form,
    page_registrados: state.page_registrados,
    registro_status: state.registro_status,
    topics: state.monitoreo__topics
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRegistrosPage: (filtros) => dispatch(getRegistrosPage(filtros)),
   /*  getMonitoreoCategoriasFormSelecTicket: () =>
      dispatch(getMonitoreoCategoriasFormSelecTicket()), */
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Guardados);