import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { 
  getFilterRequest, 
  getLogin 
} from "../../store";

// Components
import { GlobalStyle } from "./styled";
import Header from "../commons/Header";
import Login from "../User/Login";
import PrivateRoutes from "../Routes/PrivateRoutes";
import Home from "src/pages/Home";
import HomeQsch from "src/pages/Qsch/HomeQsch";
import HomeTextos from "src/pages/Textos/HomeTextos";
import HomeDesgrabador from "src/pages/Desgrabador/HomeDesgrabador";
import HomeChequeador from "src/pages/Chequeador/index";
import HomePush from "src/pages/Push/HomePush";
import HomeMonitoreo from "src/pages/Monitoreo/HomeMonitoreo";
import HomeUser from "src/pages/User/HomeUser";
import CodeUser from "src/pages/User/CodeUser";
import CreatePassword from "src/pages/User/CreatePassword";
import ResetPassword from "src/pages/User/ResetPassword";
import CreatePasswordComplete from "src/pages/User/CreatePasswordComplete";
import FormCreatePublication from "../Monitoreo/Forms/FormCreatePublication";
import ChequeadorFormNew from "src/pages/Chequeador/ChequeadorFormNew";
import ChequeadorFormEditar from "src/pages/Chequeador/ChequeadorFormEditar";

function App(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [isHeaderOpen, setIsHeaderOpen] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.getLogin(true);
    }
  }, []);


   const onClick = () => {
     setShowOverlay(false);
   };


  return (
    <>
      <HashRouter>
        <div className={`app-container ${isHeaderOpen ? "opened" : "close"}`}>
          {showOverlay && (
            <div
              className="modal h-100 w-100 d-block d-lg-none d-xl-none bg-transparent align-middle"
              id="myModal"
              tabIndex="-1"
              role="dialog"
            >
              <h3 className="text-center text-black font-weight-bold">
                Esta herramienta esta diseñada para usar en escritorio.
              </h3>
              <button
                type="button"
                className="close pr-5 pt-5 font-weight-bold text-black"
                style={{ fontSize: "54px" }}
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClick}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          )}
          <Header />
          <GlobalStyle />
          <Switch>
            <PrivateRoutes path="/" exact component={Home} />
            <Route path="/login" exact component={Login} />
            <Route path="/user/invitation" exact component={CodeUser} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <Route
              path="/user/reset-password-complete"
              exact
              component={CreatePasswordComplete}
            />
            <PrivateRoutes path="/qsch" exact component={HomeQsch} />
            <PrivateRoutes
              path="/desgrabador"
              exact
              component={HomeDesgrabador}
            />
            <PrivateRoutes
              path="/chequeador"
              exact
              component={HomeChequeador}
            />
            <PrivateRoutes
              path="/chequeador/:id"
              exact
              component={ChequeadorFormNew}
            />
            <PrivateRoutes
              path="/chequeador/edit/:id"
              exact
              component={ChequeadorFormEditar}
            />

            <PrivateRoutes path="/textos" exact component={HomeTextos} />
            <PrivateRoutes path="/push" exact component={HomePush} />
            <PrivateRoutes path="/periscopio" exact component={HomeMonitoreo} />
            <PrivateRoutes
              path="/periscopio/:id"
              exact
              component={HomeMonitoreo}
            />
            <PrivateRoutes path="/user" exact component={HomeUser} />
            <PrivateRoutes
              path="/user/create-password"
              exact
              component={CreatePassword}
            />
          </Switch>
        </div>
      </HashRouter>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    filter: state.filter,
    login: state.login,
    token: state.token,
    url: state.url
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFilterRequest: () => dispatch(getFilterRequest()),
    getLogin: (status) => dispatch(getLogin(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);