import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { Container, Row, Col, Button } from "react-bootstrap";

import { FaLink } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import { BiSpreadsheet } from "react-icons/bi";
import { MdArrowBackIos } from "react-icons/md";
import "react-quill/dist/quill.snow.css";
import "./index.css";
import Logo from "src/components/Chequeador/Logo";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { FaPaperclip } from "react-icons/fa";
import styled from "styled-components";
/* Redux */
import { connect } from "react-redux";
import {
  getVerificaionesID,
  putVerificaionesNueva,
  fetchVerficacionesBorrador,
  fetchVerficacionesRating,
  resetChequeadorVerificacionesNew,
  fetchVerficacionesHtmlNuevo,
} from "src/store";

/* Styled */
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
`;

const InputWrapperRed = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  flex-direction: column;
  margin-top: 5px;
`;

const InputContainer = styled.div`
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;

  svg {
    color: #7f7f7f;
  }
`;

const SocialNetworkInput = styled.input`
  width: 100% !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

const StyledInput = styled.input`
  display: none;
`;

const UploadButton = styled.button`
  border: 1px solid #354052;
  color: #354052;
  background: 0 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 0px 33px !important;
  border-radius: 3px;
  &:hover {
    background-color: #354052;
    border: 1px solid #354052;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
`;

const Placeholder = styled.span`
  color: #c4c4c4;
  margin-left: 10px;
  font-size: 12px;
`;

/* const PreviewContainer = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`; */

const PreviewContainer = styled.div`
  width: 200px; // Aumentado para acomodar ambas imágenes
  height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  display: flex;
  gap: 10px; // Espacio entre las imágenes
  width: 100%;
  height: 100%;
  padding: 5px; // Añade un poco de espacio interno
`;

const PreviewImage = styled.img`
  width: 50%; // Cada imagen ocupa la mitad del espacio
  height: 100%;
  object-fit: cover;
`;

const ButtonSave = styled.button`
  border: 1px solid #354052;
  color: #354052;
  background: 0 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 0px 20px !important;
  border-radius: 17px;
  cursor: pointer;

  /* Estilos condicionales basados en la prop "saved" */
  ${(props) =>
    props.saved &&
    `
    background: #73bd82;
    border: 1px solid #73bd82;
    color: white;
    pointer-events: none; /* Deshabilita los eventos de puntero */
    cursor: default; /* Cambia el cursor a default */
  `}
  &:hover {
    background-color: #354052;
    border: 1px solid #354052;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
`;

export const StyledMessageAccordion = styled(Accordion)`
  width: 140px;
  cursor: pointer;
  .card-header-1 {
    background: transparent !important;
    border-bottom: none !important;
    width: 120px;
  }
  .card-header {
    background: transparent !important;
    border-bottom: none !important;
    width: 140px;
  }
  .resetpass {
    font-size: 16px;
    line-height: 20px;
    color: #354052;
  }
  .card-body {
    padding: 0 0 0 0 !important;
  }
`;

const ButtonsFinalDoc = styled.button`
  border: 1px solid #fff;
  color: #354052;
  background: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding: 0px 20px !important;
  border-radius: 17px;
  cursor: pointer;
  &:hover {
    background-color: #354052;
    border: 1px solid #354052;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
`;

export const MainSectorImageUpload = ({
  formData,
  handleDrop,
  handleDragOver,
  handleFileChange,
}) => {
  const fileInputRef = useRef(null);

  return (
    <InputWrapper>
      <InputContainer onDrop={handleDrop} onDragOver={handleDragOver}>
        <FaPaperclip />
        <Placeholder>
          {formData.contentScreenshot
            ? "Imagen seleccionada"
            : "Click para buscar un archivo"}
        </Placeholder>
        <UploadButton onClick={() => fileInputRef.current.click()}>
          Buscar
        </UploadButton>
        <StyledInput
          id="contentScreenshot"
          type="file"
          name="contentScreenshot"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
        />
      </InputContainer>
      {formData.contentScreenshot && !formData.contentScreenshotWatermarked && (
        <PreviewContainer style={{ border: "1px solid #ddd" }}>
          <PreviewImage src={formData.contentScreenshot} alt="Preview" />
        </PreviewContainer>
      )}

      {formData.contentScreenshotWatermarked && (
        <>
          <PreviewContainer style={{ border: "1px solid #ddd" }}>
            <PreviewImage src={formData.contentScreenshot} alt="Preview" />
          </PreviewContainer>
          <PreviewContainer style={{ border: "1px solid #ddd" }}>
            <PreviewImage
              src={formData.contentScreenshotWatermarked}
              alt="Preview"
            />
          </PreviewContainer>
        </>
      )}
    </InputWrapper>
  );
};

export const SocialNetworkImageUpload = ({
  network,
  handleDrop,
  handleDragOver,
  handleFileChange,
}) => {
  const fileInputRef = useRef(null);
  return (
    <InputWrapperRed>
      <InputContainer
        width="100%"
        style={{ width: "100%", padding: " 0 6px !important" }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <FaPaperclip />
        <Placeholder>
          {network.screenshot
            ? "Imagen seleccionada"
            : "Click para buscar un archivo"}
        </Placeholder>
        <UploadButton onClick={() => fileInputRef.current.click()}>
          Buscar
        </UploadButton>
        <StyledInput
          id={`networkScreenshot`}
          type="file"
          name={`networkScreenshot`}
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
        />
      </InputContainer>
      {network.screenshot && !network.screenshotWatermarked && (
        <PreviewContainer>
          <ImageWrapper>
            <PreviewImage src={network.screenshot} alt="Preview" />
          </ImageWrapper>
        </PreviewContainer>
      )}

      {network.screenshotWatermarked && (
        <PreviewContainer>
          <ImageWrapper>
            <PreviewImage src={network.screenshot} alt="Preview" />
            <PreviewImage src={network.screenshotWatermarked} alt="Preview" />
          </ImageWrapper>
        </PreviewContainer>
      )}
    </InputWrapperRed>
  );
};
const ChequeadorFormNew = (props) => {
  const { id } = useParams();
  const history = useHistory();

  // Estados
  const [buttonText, setButtonText] = useState("Guardar");
  const [saved, setSaved] = useState(false);
  const [show, setShow] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [draftHtml, setDraftHtml] = useState("");
  const [buttonTextPrimerBorrador, setbuttonTextPrimerBorrador] = useState(
    "Generar borrador de nota"
  );
  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: "#73BD82",
    color: "#fff",
    border: "1px solid #73BD82",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showGeneratedNote, setShowGeneratedNote] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState("");
  const [copiedContent, setCopiedContent] = useState("");
  const [formData, setFormData] = useState({
    title: props.chequeador__verificiones__new.title || "",
    contentTypes: [],
    contentDescription: null,
    contentScreenshot: null,
    socialNetworks: [],
    toolsUsed: [],
    context: null,
    ratingId: null,
    ratingJustification: null,
    googleDocUrl: null,
    contentScreenshotWatermarked: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [formMode, setFormMode] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  /* useEffects */

  useEffect(() => {
    if (props.chequeador__verificiones__rating.length === 0) {
      props.fetchVerficacionesRating();
    }
  }, [props.fetchVerficacionesRating, props.chequeador__verificiones__rating]);

  useEffect(() => {
    if (props.chequeador__verificiones__new) {
      const timeoutId = setTimeout(() => {
        if (!formData.title || formData.title.trim() === "") {
          return;
        }

        props.putVerificaionesNueva(id, formData);
      }, 60000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [formData, id, props.chequeador__verificiones__new, props]);

  useEffect(() => {
    const fetchVerificacionData = async () => {
      try {
        await props.getVerificaionesID(id);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching verification data:", error);
        setIsLoading(false);
      }
    };

    fetchVerificacionData();
  }, [id, props.getVerificaionesID]);

  useEffect(() => {
    if (props.chequeador__verificiones__id) {
      setFormData({
        title: props.chequeador__verificiones__id.title || "",
        contentTypes: props.chequeador__verificiones__id.contentTypes || [],
        contentDescription:
          props.chequeador__verificiones__id.contentDescription || null,
        contentScreenshot:
          props.chequeador__verificiones__id.contentScreenshot || null,
        socialNetworks: props.chequeador__verificiones__id.socialNetworks || [
          { name: "", metrics: "", screenshot: "" },
        ],
        toolsUsed: props.chequeador__verificiones__id.toolsUsed || [],
        context: props.chequeador__verificiones__id.context || "",
        ratingId: props.chequeador__verificiones__id.ratingId || null,
        ratingJustification:
          props.chequeador__verificiones__id.ratingJustification || null,
        contentScreenshotWatermarked:
          props.chequeador__verificiones__id.contentScreenshotWatermarked || "",
      });
    }
  }, [props.chequeador__verificiones__id]);

  useEffect(() => {
    if (props.chequeador__verificiones__id) {
      setFormMode("refresh");
    } else if (props.chequeador__verificiones__new) {
      setFormMode("new");
    }
  }, [props.chequeador__verificiones__id, props.chequeador__verificiones__new]);

  useEffect(() => {
    const fetchAndSetDraftHtml = async () => {
      try {
        await props.fetchVerficacionesHtmlNuevo(id);
      } catch (error) {
        console.error("Error al obtener el borrador:", error);
      }
    };

    fetchAndSetDraftHtml();
  }, [props.fetchVerficacionesHtmlNuevo]);

  useEffect(() => {
    setDraftHtml(props.chequeador__verificaciones__borrador__data__nuevo);
  }, [props.chequeador__verificaciones__borrador__data__nuevo]);

  const generateNote = async () => {
    let hasEmptyFields = false;

    if (!formData.ratingId || !formData.contentScreenshot || !formData.title) {
      hasEmptyFields = true;
    }

    if (hasEmptyFields) {
      setShow(true);
    } else {
      try {
        setShowGeneratedNote(true);
        setbuttonTextPrimerBorrador("Generar");
        setButtonStyle({
          backgroundColor: "transparent",
          color: "#333",
          border: "1px solid #333",
        });
        const formattedData = {
          contentDescription: formData.contentDescription || null,
          contentScreenshot: formData.contentScreenshot || "",
          contentScreenshotWatermarked:
            formData.contentScreenshotWatermarked || "",
          contentTypes: formData.contentTypes || [],
          context: formData.context || "",
          ratingId: formData.ratingId || "",
          ratingJustification: formData.ratingJustification || null,
          socialNetworks: formData.socialNetworks || [],
          title: formData.title || "",
          toolsUsed: formData.toolsUsed || [],
        };

        await props.putVerificaionesNueva(id, formattedData);

        const ratingObject = props.chequeador__verificiones__rating.find(
          (rating) => rating.id === formData.ratingId
        );
        const ratingLabel = ratingObject ? ratingObject.label : "Desconocido";

        const title = formData.title
          ? `<h1 style="background: #FFF">${formData.title}</h1>`
          : "";
        const mainScreenshot = formData.contentScreenshot
          ? `<a href="${formData.contentScreenshot}">Captura principal</a>`
          : "";
        const watermarkedScreenshot = formData.contentScreenshotWatermarked
          ? `<a href="${formData.contentScreenshotWatermarked}">Captura con marca de agua</a>`
          : "";
        const watermarkedCircula = formData.contentScreenshotWatermarked
          ? `Circula en redes sociales. <a href="${formData.contentScreenshotWatermarked}"></a>`
          : "El contenido no circuló por redes sociales.";

        const socialNetworkTexts = formData.socialNetworks.map((network) => ({
          name: network.name,
          screenshot: network.screenshot,
          metrics: network.metrics,
        }));

        const socialNetworks =
          socialNetworkTexts.length > 0
            ? `El contenido circuló por ${socialNetworkTexts
                .map((network) => network.name)
                .join(", ")}.`
            : "";
        const ratingId = `Esto es ${ratingLabel}.`;
        const conclusionText = `Luego de realizar el procedimiento de verificación se concluye que la imagen modificada es ${ratingLabel}.`;
        const shares =
          socialNetworkTexts.length > 0
            ? socialNetworkTexts
                .map(
                  (network) =>
                    `El contenido tuvo ${network.metrics} en <a href="${network.screenshot}">${network.name}</a>`
                )
                .join(", ")
            : "";

        const conclusionParts = formData.toolsUsed
          ? formData.toolsUsed.map((tool) => tool.conclusion).filter(Boolean)
          : [];
        const conclusionSection =
          conclusionParts.length > 0
            ? `<h2>¿Cómo llegamos a esta conclusión?</h2>${conclusionParts.join(
                ""
              )}`
            : "";
        const contentDescription = formData.context
          ? `<p>${formData.context}</p>`
          : "";
        const ratingJustification = formData.ratingJustification
          ? `<p>Por todo esto, concluimos que este contenido es ${ratingLabel}. ${formData.ratingJustification}.</p>`
          : "";

        const formattedContent = `
        <div style="font-family: Arial, sans-serif;background-color: #fff !important">
          ${title}
          ${mainScreenshot}
          ${watermarkedScreenshot}
          ${watermarkedCircula}
          ${socialNetworks} ${ratingId} ${conclusionText}
          ${shares}
          ${conclusionSection}
          ${contentDescription}
          ${ratingJustification}
        </div>
      `;
        await props.fetchVerficacionesBorrador(id, formattedContent);
      } catch (error) {
        console.error("Error guardando y generando el borrador:", error);
        setShowGeneratedNote(false);
        setbuttonTextPrimerBorrador("Generar borrador de nota");
        setButtonStyle({
          backgroundColor: "#73BD82",
          color: "#fff",
          border: "1px solid #73BD82",
        });
        alert(
          "Ocurrió un error al generar el borrador. Por favor, inténtalo de nuevo."
        );
      }
    }
  };

  const copyNoteToClipboard = async () => {
    try {
      const formattedData = {
        contentDescription: formData.contentDescription || "",
        contentScreenshot: formData.contentScreenshot || "",
        contentScreenshotWatermarked:
          formData.contentScreenshotWatermarked || "",
        contentTypes: formData.contentTypes || [],
        context: formData.context || "",
        ratingId: formData.ratingId || "",
        ratingJustification: formData.ratingJustification || "",
        socialNetworks: formData.socialNetworks || [],
        title: formData.title || "",
        toolsUsed: formData.toolsUsed || [],
      };

      if (!isSaved) {
        await props.putVerificaionesNueva(id, formattedData);
        setIsSaved(true);
      }

      const ratingObject = props.chequeador__verificiones__rating.find(
        (rating) => String(rating.id) === String(formData.ratingId)
      );

      const ratingLabel = ratingObject.label;

      const title = formData.title
        ? `<h1 style="background: #FFF">${formData.title}</h1>`
        : "";
      const mainScreenshot = formData.contentScreenshot
        ? `<a href="${formData.contentScreenshot}">Captura principal</a>`
        : "";
      const watermarkedScreenshot = formData.contentScreenshotWatermarked
        ? `<a href="${formData.contentScreenshotWatermarked}">Captura con marca de agua</a>`
        : "";
      const watermarkedCircula = formData.contentScreenshotWatermarked
        ? `Circula en redes sociales. <a href="${formData.contentScreenshotWatermarked}"></a>`
        : "El contenido no circuló por redes sociales.";

      const socialNetworkTexts = formData.socialNetworks.map((network) => ({
        name: network.name,
        screenshot: network.screenshot,
        metrics: network.metrics,
      }));

      const socialNetworks =
        socialNetworkTexts.length > 0
          ? `El contenido circuló por ${socialNetworkTexts
              .map((network) => network.name)
              .join(", ")}.`
          : "";

      const ratingId = `Esto es ${ratingLabel}.`;
      const conclusionText = `Luego de realizar el procedimiento de verificación se concluye que la imagen modificada es ${ratingLabel}.`;

      const shares =
        socialNetworkTexts.length > 0
          ? socialNetworkTexts
              .map((network) => {
                return `El contenido tuvo ${network.metrics} en <a href="${network.screenshot}">${network.name}</a>`;
              })
              .join(", ")
          : "";

      const conclusionParts = formData.toolsUsed
        ? formData.toolsUsed
            .map((tool) => {
              if (tool.conclusion) {
                return `${tool.conclusion}`;
              }
              return "";
            })
            .filter(Boolean)
        : [];

      const conclusionSection =
        conclusionParts.length > 0
          ? `<h2>¿Cómo llegamos a esta conclusión?</h2>${conclusionParts.join(
              ""
            )}`
          : "";

      const contentDescription = formData.context
        ? `<p>${formData.context}</p>`
        : "";
      const ratingJustification = formData.ratingJustification
        ? `<p>Por todo esto, concluimos que este contenido es ${ratingLabel}. ${formData.ratingJustification}.</p>`
        : "";

      const formattedContent = `
      <div style="font-family: Arial, sans-serif;background-color: #fff !important">
        ${title}
        ${mainScreenshot}
        ${watermarkedScreenshot}
        ${watermarkedCircula}
        ${socialNetworks} ${ratingId} ${conclusionText}
        ${shares}
        ${conclusionSection}
        ${contentDescription}
        ${ratingJustification}
      </div>
    `;

      const tempDiv = document.createElement("div");
      tempDiv.style.position = "absolute";
      tempDiv.style.left = "-9999px";
      tempDiv.innerHTML = formattedContent;
      document.body.appendChild(tempDiv);

      const range = document.createRange();
      range.selectNodeContents(tempDiv);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      document.execCommand("copy");
      selection.removeAllRanges();
      alert("El contenido ha sido copiado.");
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Error del servidor:", error.response.data);
      }
      alert(
        "Hubo un error al procesar el contenido. Por favor, inténtalo de nuevo."
      );
    } finally {
      const tempDiv = document.querySelector("div[style*='-9999px']");
      if (tempDiv) {
        document.body.removeChild(tempDiv);
      }
    }
  };

  const savePublication = async () => {
    if (!formData.title || formData.title.trim() === "") {
      setShowSave(true);
      return;
    }

    try {
      console.log(formData.title);
      await props.putVerificaionesNueva(id, formData);
      setButtonText("Guardado");
      setShowSavedMessage("La verificación se guardó exitosamente");
      setSaved(true);

      setTimeout(() => {
        setButtonText("Guardar");
        setShowSavedMessage("");
        setSaved(false);
      }, 3000);
    } catch (error) {
      console.error("Error saving verification:", error);
      setErrorMessage("Ocurrió un error al guardar la verificación.");
    }
  };

  const handleCloseSave = () => setShowSave(false);
  const handleClose = () => setShow(false);

  const fileInputRefs = useRef({});

  const toolPlaceholderText =
    "Resultados de usar la herramienta (agregar hipervínculos en el texto a los resultados de la herramienta). En caso de no haber resutlados también podés comunicarlo.";

  const pairNetworks = (networks) => {
    const pairs = [];
    for (let i = 0; i < networks.length; i += 2) {
      pairs.push(networks.slice(i, i + 2));
    }
    return pairs;
  };

  const networkPairs = pairNetworks(formData.socialNetworks);

  const modulesMinimal = {
    toolbar: [["bold", "italic", "underline", "strike"], ["link"]],
  };

  const formatsMinimal = ["bold", "italic", "underline", "strike", "link"];

  // Definición de las herramientas
  const tools = [
    {
      id: "googleKeywords",
      name: "Búsqueda por palabras",
      description: "Permite buscar información usando palabras claves.",
      placeholder: toolPlaceholderText,
      category: "general",
      examples: [
        { name: "Google", link: "https://google.com" },
        { name: "Bing", link: "https://bing.com" },
      ],
    },
    {
      id: "images",
      name: "Herramientas para imágenes",
      description: "Permite buscar coincidencias visuales en la  web.",
      placeholder: toolPlaceholderText,
      category: "image",
      examples: [
        { name: "Google Imágenes", link: "https://images.google.com/" },
        { name: "TinEye", link: "https://tineye.com/" },
      ],
    },
    {
      id: "videos",
      name: "Herramientas para videos",
      description:
        "Permite generar fotogramas de vídeos para facilitar la búsqueda.",
      placeholder: toolPlaceholderText,
      category: "image",
      examples: [
        {
          name: "InVID",
          link: "https://chromewebstore.google.com/detail/fake-news-debunker-by-inv/mhccpoafgdgbhnjfhkcmgknndkeenfhe",
        },
        {
          name: "YouTube Data Viewer",
          link: "https://ytlarge.com/youtube/video-data-viewer/",
        },
      ],
    },
    {
      id: "ia",
      name: "Herramientas para IA",
      description: "Permite detectar contenido generado con IA.",
      placeholder: toolPlaceholderText,
      category: "video",
      examples: [
        {
          name: "Hive Moderation (texto, imagen, video audio)",
          link: "https://hivemoderation.com/ai-generated-content-detection",
        },
        { name: "Deepware (video)", link: "https://scanner.deepware.ai/" },
        { name: "VerificAudio (audio)", link: "https://verificaudio.ai/" },
      ],
    },
    {
      id: "fact-check-explorer",
      name: "Fact Check Explorer",
      description:
        "Permite encontrar verificaciones sobre temas ya investigados por organizaciones de fact checking.",
      placeholder: toolPlaceholderText,
      category: "audio",
      examples: [
        {
          name: "Fact Check Explorer",
          link: "https://toolbox.google.com/factcheck/explorer/search/list:recent;hl=es",
        },
      ],
    },
    {
      id: "other-tools",
      name: "Otras herramientas",
      description: "Más recursos, apps y programas.",
      placeholder: toolPlaceholderText,
      category: "all",
      examples: [
        {
          name: "Caja de Herramientas",
          link: "https://chequeado.com/herramientasparaperiodistas/index.html#/",
        },
      ],
    },
    {
      id: "contactSources",
      name: "Consultar fuentes",
      description: "Verificar el contenido con fuentes y/o documentos.",
      placeholder:
        "¿Qué fuentes consultaste y que información obtuviste? Hipervíncula todo contenido relevante (perfiles, documentos, etc.).",
      category: "general",
      examples: [],
    },
  ];

  // Funciones de navegación
  const returnToHome = () => {
    if (props.chequeador__verificiones__new) {
      props.resetChequeadorVerificacionesNew();
      history.push("/chequeador");
      props.resetChequeadorVerificacionesNew();
    } else {
      history.push(-1);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value || null,
    }));
  };

  const handleContentTypeChange = (type) => {
    setFormData((prevData) => {
      const updatedTypes = prevData.contentTypes.includes(type)
        ? prevData.contentTypes.filter((t) => t !== type)
        : [...prevData.contentTypes, type];
      return { ...prevData, contentTypes: updatedTypes };
    });
  };

  const handleSocialNetworkChange = (index, field, value) => {
    const updatedNetworks = [...formData.socialNetworks];
    updatedNetworks[index] = { ...updatedNetworks[index], [field]: value };
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: updatedNetworks,
    }));
  };

  const addSocialNetwork = () => {
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: [
        ...prevData.socialNetworks,
        { name: "", metrics: "", screenshot: null },
      ],
    }));
  };

  const removeSocialNetwork = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      socialNetworks: prevData.socialNetworks.filter((_, i) => i !== index),
    }));
  };

  const handleToolUse = (toolId, isUsed) => {
    setFormData((prevData) => {
      const toolIndex = prevData.toolsUsed.findIndex(
        (t) => t.toolId === toolId
      );
      if (isUsed) {
        if (toolIndex === -1) {
          return {
            ...prevData,
            toolsUsed: [...prevData.toolsUsed, { toolId, conclusion: "" }],
          };
        } else {
          return prevData;
        }
      } else {
        if (toolIndex !== -1) {
          return {
            ...prevData,
            toolsUsed: prevData.toolsUsed.filter(
              (_, index) => index !== toolIndex
            ),
          };
        } else {
          return prevData;
        }
      }
    });
  };

  const handleToolConclusion = (conclusion, toolId) => {
    setFormData((prevData) => ({
      ...prevData,
      toolsUsed: prevData.toolsUsed.map((tool) =>
        tool.toolId === toolId ? { ...tool, conclusion } : tool
      ),
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          contentScreenshot: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSocialNetworkFileChange = (event, networkIndex) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => {
          const updatedNetworks = [...prevState.socialNetworks];
          updatedNetworks[networkIndex] = {
            ...updatedNetworks[networkIndex],
            screenshot: reader.result,
          };
          return {
            ...prevState,
            socialNetworks: updatedNetworks,
          };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event, networkIndex) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        handleSocialNetworkChange(networkIndex, "screenshot", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {isLoading ? (
        <div className="container-fluid">
          <div className="row container-title">
            <div className="col-lg-12">
              <div className="titulo">
                <Logo />
                <p>El Chequeador</p>
              </div>
            </div>
          </div>
          <div className="loading">Cargando verificación...</div>
        </div>
      ) : (
        <div className="container-fluid ">
          <div className="row container-title">
            <div className="col-lg-12">
              <div className="titulo">
                <Logo />
                <p> El Chequeador</p>
              </div>
            </div>
          </div>

          <div className="container-fluid" style={{ margin: "0" }}>
            <header className="app-header">
              <div className="row">
                <Col xs={12} sm={12} md={7} lg={7}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <h1
                      onClick={() => returnToHome()}
                      style={{ cursor: "pointer" }}
                    >
                      <MdArrowBackIos />
                      Todas las verificaciones
                    </h1>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ButtonSave onClick={savePublication} saved={saved}>
                        {buttonText}
                      </ButtonSave>
                      <span
                        style={{
                          color: "#7F7F7F",
                          fontSize: "11px",
                          fontStyle: "italic",
                          marginTop: "8px",
                        }}
                      >
                        {showSavedMessage ? (
                          <p style={{ marginBottom: "0px" }}>
                            {showSavedMessage}
                          </p>
                        ) : (
                          "Guardado automático cada 1 minuto"
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
              </div>
            </header>

            <main className="app-main">
              <section className="new two-column-section row">
                <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                  <h2>Titulo</h2>
                  <input
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  />
                </div>
              </section>
              <section className="new two-column-section row">
                <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                  <h2>¿Qué vas a verificar?</h2>
                  <div className="card" style={{ paddingLeft: "0" }}>
                    <fieldset className="content-type-selection">
                      <legend>
                        Seleccioná el tipo de formato{" "}
                        <span>Seleccionar todos los que correspondan.</span>
                      </legend>
                      {["Texto", "Audio", "Imagen", "Video"].map((type) => (
                        <label key={type} className="checkbox-label">
                          <input
                            type="checkbox"
                            checked={formData.contentTypes.includes(type)}
                            onChange={() => handleContentTypeChange(type)}
                          />
                          <span className="checkbox-custom"></span>
                          {type}
                        </label>
                      ))}
                    </fieldset>
                    <label htmlFor="contentDescription">
                      Descripción del contenido
                    </label>
                    <textarea
                      id="contentDescription"
                      name="contentDescription"
                      value={formData.contentDescription}
                      onChange={handleInputChange}
                      placeholder="Proporcioná una justificación..."
                      className="textarea-input"
                    />
                    <label htmlFor="contentScreenshot">
                      Captura de pantalla
                    </label>
                    <MainSectorImageUpload
                      formData={formData}
                      handleDrop={handleDrop}
                      handleDragOver={handleDragOver}
                      handleFileChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                  <div className="tips-card">
                    <h3>Recomendaciones</h3>
                    <ul>
                      <li>
                        Seleccioná contenidos fácticos que puedan ser
                        contrastados.
                      </li>
                      <li>
                        Priorizá contenidos basándote en:
                        <ul>
                          <li>La relevancia del tema aludido.</li>
                          <li>
                            La gravedad de las consecuencias potenciales de la
                            desinformación.
                          </li>
                          <li>
                            Su circulación y viralización en redes sociales u
                            otros medios.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Describí el contenido.
                        <ul>
                          <li>
                            Si es una imagen o video, describí detalladamente lo
                            que se ve.
                          </li>
                          <li>
                            Incluí detalles como fechas, lugares o personas
                            mencionadas, si están disponibles.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <div className="warning-card-container">
                    <div className="warning-card">
                      <h3>¡Atención!</h3>
                      <ul>
                        <li>
                          No compartas el link del posteo original. Hacerlo
                          puede dar mayor visibilidad al contenido
                          potencialmente desinformante.
                        </li>
                        <li>
                          Guardá una captura del contenido para resguardarte en
                          caso de que se borre o edite el contenido original.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <section className="-new two-third-column-section row">
                <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                  <h2>¿Dónde está circulando el contenido ahora?</h2>
                  <div className="row examples-section">
                    <div className="col-7">
                      <p>
                        Ingresá los datos de las redes sociales donde el
                        contenido tuvo la mayor cantidad de interacciones.{" "}
                        <br />
                        <br />
                        El resto de las redes donde circuló con menor viralidad
                        los podés agregar más adelante en el borrador de la
                        nota.
                      </p>
                    </div>
                  </div>
                  <Container>
                    <Row>
                      {formData.socialNetworks.map((network, index) => (
                        <Col md={6} style={{ paddingLeft: "0" }}>
                          <div key={index} className="social-network-box">
                            <div className="social-network-header">
                              <h4>Red social {index + 1}</h4>
                              <button
                                onClick={() => removeSocialNetwork(index)}
                                className="button-icon"
                                aria-label="Eliminar red social"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  width="24"
                                  height="24"
                                >
                                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                </svg>
                              </button>
                            </div>
                            <div className="social-network-fields">
                              <label htmlFor={`networkName-${index}`}>
                                Red social
                              </label>
                              <input
                                id={`networkName-${index}`}
                                type="text"
                                value={network.name}
                                onChange={(e) =>
                                  handleSocialNetworkChange(
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                                placeholder="Ingresá una red social (ej: Facebook)"
                                className="text-input"
                              />
                              <label htmlFor={`networkMetrics-${index}`}>
                                Métricas
                              </label>
                              <input
                                id={`networkMetrics-${index}`}
                                type="text"
                                value={network.metrics}
                                onChange={(e) =>
                                  handleSocialNetworkChange(
                                    index,
                                    "metrics",
                                    e.target.value
                                  )
                                }
                                placeholder='Ingresá las métricas más representativas de la red (ej: 600 mil reproducciones y 17 mil "Me gusta")'
                                className="text-input"
                              />
                              <label>Captura de pantalla</label>
                              <SocialNetworkImageUpload
                                network={network}
                                handleDrop={handleDrop}
                                handleDragOver={handleDragOver}
                                handleFileChange={(e) =>
                                  handleSocialNetworkFileChange(e, index)
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Button
                          onClick={addSocialNetwork}
                          variant="secondary"
                          className="agregar-red"
                        >
                          Agregar red social
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                  <div className="tips-card">
                    <h3>Recomendaciones</h3>
                    <ul>
                      <li>
                        Monitoreá y registrá la circulación en diversas
                        plataformas:
                      </li>
                      <ul>
                        <li>Redes sociales (Facebook, X, Instagram, etc.).</li>
                        <li>
                          Aplicaciones de mensajería (WhatsApp, Telegram, etc.).
                        </li>
                        <li>
                          Medios de comunicación tradicionales y digitales.
                        </li>
                      </ul>
                      <li>
                        Proporcioná datos cuantitativos cuando estén
                        disponibles:
                      </li>
                      <ul>
                        <li>Número de compartidos.</li>
                        <li>Likes o reacciones.</li>
                        <li>Comentarios.</li>
                        <li>Visualizaciones o reproducciones.</li>
                      </ul>
                      <li>
                        Indicá la fecha en que empezó a circular el contenido,
                        si es posible.
                      </li>
                      <li>
                        Mencioná si figuras públicas o medios de comunicación
                        han compartido el contenido.
                      </li>
                      <li>
                        Guardá capturas de pantalla de la circulación en cada
                        plataforma para documentación.
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className="new two-third-column-section row">
                <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                  <h2>¿Cómo vas a investigar el contenido?</h2>
                  <div className="row examples-section">
                    <div className="col-7">
                      <p>
                        Utilizá todas las herramientas que necesites para
                        encontrar el contenido original u otra información que
                        te permita avanzar con el proceso de verificación.
                      </p>
                    </div>
                  </div>
                  <div className="tools-grid">
                    {tools.map((tool) => (
                      <div key={tool.id} className="tool-card">
                        <label
                          className="checkbox-label"
                          style={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          <input
                            type="checkbox"
                            checked={formData.toolsUsed.some(
                              (t) => t.toolId === tool.id
                            )}
                            onChange={(e) =>
                              handleToolUse(tool.id, e.target.checked)
                            }
                          />
                          <span
                            className="checkbox-custom"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          ></span>
                          {tool.name}
                        </label>
                        <p>{tool.description}</p>
                        {tool.examples && tool.examples.length > 0 && (
                          <div className="tool-examples">
                            <h4>Herramientas recomendadas</h4>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {tool.examples.map((example, index) => (
                                <div key={index}>
                                  <FaLink size={14} />{" "}
                                  <a
                                    className="tool-examples-link"
                                    href={example.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {example.name}
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {formData.toolsUsed.some(
                          (t) => t.toolId === tool.id
                        ) && (
                          <ReactQuill
                            theme="snow"
                            value={
                              formData.toolsUsed.find(
                                (t) => t.toolId === tool.id
                              )?.conclusion || ""
                            }
                            onChange={(content) =>
                              handleToolConclusion(content, tool.id)
                            }
                            modules={modulesMinimal}
                            formats={formatsMinimal}
                            placeholder={tool.placeholder}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tips-column hidde-mobile  col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                  <div className="tips-card ">
                    <h3>Recomendaciones</h3>
                    <ul>
                      <li>Para imágenes y videos:</li>
                      <ul>
                        <li>
                          Utilizá herramientas de búsqueda inversa de imágenes.
                        </li>
                        <li>
                          Verificá metadatos (fecha, hora y lugar de creación).
                        </li>
                        <li>
                          Utilizá herramientas para detectar generaciones con
                          IA.
                        </li>
                      </ul>
                      <li>Para audios:</li>
                      <ul>
                        <li>
                          Contactá con las fuentes o personas mencionadas.
                        </li>
                        <li>
                          Utilizá herramientas para detectar generación con IA.
                        </li>
                      </ul>
                      <li>
                        Intentá contactar a la fuente original del contenido
                        para conocer su fundamento.
                      </li>
                      <li>
                        Si es posible, contactá a los actores involucrados o
                        afectados por la desinformación.
                      </li>
                      <li>Consultá fuentes alternativas como:</li>
                      <ul>
                        <li>Informes y análisis de expertos.</li>
                        <li>
                          Recopilaciones estadísticas de fuentes confiables.
                        </li>
                      </ul>
                      <li>
                        Documentá todos tus hallazgos y cita todas las fuentes
                        utilizadas.
                      </li>
                      <li>
                        Incluí enlaces a los documentos y datos originales.
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className="new two-column-section row">
                <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                  <h2>¿Qué contexto podés aportar sobre este contenido?</h2>
                  <div className="card" style={{ paddingLeft: "0" }}>
                    <label htmlFor="context" style={{ marginBottom: "14px" }}>
                      Contexto
                    </label>
                    <textarea
                      id="context"
                      name="context"
                      value={formData.context}
                      onChange={handleInputChange}
                      placeholder="¿Qué contexto podés aportar sobre este contenido?"
                      className="textarea-input"
                    />
                  </div>
                </div>
                <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                  <div className="tips-card">
                    <h3>Recomendaciones</h3>
                    <p>
                      Algunas preguntas que pueden ayudar a construir el
                      contexto:
                    </p>
                    <ul>
                      <li>
                        ¿La circulación del contenido se da en el marco de algún
                        evento político, económico o social de relevancia?
                        ¿Cuál? ¿Cómo se relaciona este contenido con ese evento?
                        (Por ejemplo campañas electorales, dictámenes o debate
                        de leyes, atentados, manifestaciones, etc.)
                      </li>
                      <li>
                        ¿El contenido ya circuló en el pasado? ¿Fue desmentido o
                        trabajado por otros chequeadores? ¿Circuló en otros
                        países?
                      </li>
                      <li>
                        ¿Forma parte de una narrativa que incluye otras
                        desinformaciones sobre el mismo tema? ¿Podés
                        proporcionar ejemplos? ¿Qué registros hay de esta
                        narrativa?
                      </li>
                      <li>
                        ¿El contenido está agrupado dentro del marco de una
                        teoría conspirativa? ¿Cuál? ¿Qué fuentes pueden
                        consultarse sobre el tema?
                      </li>
                      <li>
                        ¿El formato de la desinformación es recurrente, como
                        placas de medios con citas falsas o imágenes o videos
                        creados con IA? Podés comentarlo y aportar consejos o
                        recomendaciones para detectarlas.
                      </li>
                    </ul>
                    <p>
                      Además, si el contenido fue elaborado sobre una temática
                      compleja, podés incluir contexto para explicar esa
                      temática.
                    </p>
                  </div>
                </div>
              </section>

              <section className="new two-column-section row">
                <div className="form-column col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                  <h2 style={{ marginBottom: "0px" }}>
                    ¿Cuál es tu conclusión?
                  </h2>
                  <div
                    className="card"
                    style={{ paddingLeft: "0", paddingTop: "17px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <label htmlFor="ratingId">Calificación</label>
                      <select
                        id="ratingId"
                        name="ratingId"
                        value={formData.ratingId || ""}
                        onChange={handleInputChange}
                        className="select-input"
                        required
                      >
                        <option value="" disabled>
                          Seleccionar veredicto
                        </option>
                        {props?.chequeador__verificiones__rating.map(
                          (option) => (
                            <option key={option.id} value={option.id}>
                              {option.label}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <label
                      htmlFor="ratingJustification"
                      style={{ marginTop: "18px" }}
                    >
                      Justificación
                    </label>
                    <textarea
                      id="ratingJustification"
                      name="ratingJustification"
                      value={formData.ratingJustification}
                      onChange={handleInputChange}
                      placeholder="Proporcioná una justificación detallada de la calificación."
                      className="textarea-input-conclusion"
                    />
                  </div>
                  {!showGeneratedNote ? (
                    <div style={{ paddingRight: "20px" }}>
                      <section className="verification-step-new  nw-section">
                        <>
                          <div className="col-12">
                            <div
                              className="form-container"
                              style={{ justifyContent: "center" }}
                            >
                              <button
                                onClick={generateNote}
                                className="button-primary nw-generate-button"
                                style={buttonStyle}
                              >
                                <BiSpreadsheet className="icon" />{" "}
                                {buttonTextPrimerBorrador}
                              </button>
                            </div>
                          </div>
                        </>
                      </section>
                    </div>
                  ) : (
                    <div style={{ paddingRight: "20px" }}>
                      <section
                        className="verification-step-new  nw-section"
                        style={{ background: "#73BD82" }}
                      >
                        <div className="form-container">
                          <>
                            <div className="col-12">
                              <div
                                className="nw-copy-section"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <a
                                  href={props.chequeador__verificiones__url}
                                  target="_blank"
                                >
                                  <ButtonsFinalDoc
                                    onClick={copyNoteToClipboard}
                                    className="button-secondary nw-copy-button"
                                    style={{ marginTop: "0", marginLeft: "0" }}
                                  >
                                    Copiar borrador
                                  </ButtonsFinalDoc>
                                </a>
                              </div>
                            </div>
                          </>
                        </div>
                      </section>
                    </div>
                  )}
                  {copiedContent ? copiedContent : null}
                </div>

                <div className="tips-column hidde-mobile col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                  <div className="tips-card">
                    <h3>Recomendaciones</h3>
                    <ul>
                      <li>Calificaciones:</li>
                      <ul>
                        <li>
                          <strong>Falso:</strong> El contenido completo ha
                          demostrado ser falso al contrastarlo con fuentes
                          confiables.
                        </li>
                        <li>
                          <strong>Engañoso:</strong> Parte del contenido puede
                          ser verdadero, pero ha sido manipulado para generar un
                          mensaje particular.
                        </li>
                        <li>
                          <strong>Verdadero:</strong> El contenido completo ha
                          demostrado ser verdadero al contrastarlo con fuentes
                          confiables.
                        </li>
                        <li>
                          <strong>Insostenible:</strong> La afirmación surge de
                          investigaciones con falta de sustento o errores
                          metodológicos graves, o es imposible de verificar.
                        </li>
                      </ul>
                      <li>
                        Basá tu calificación en la evidencia recopilada y las
                        fuentes consultadas.
                      </li>
                      <li>
                        Justificá tu calificación con hechos concretos y citas
                        de fuentes confiables.
                      </li>
                      <li>
                        Si hay aspectos que no pudiste verificar completamente,
                        mencionalo en tu justificación.
                      </li>
                      <li>
                        Sé transparente sobre el proceso de verificación y las
                        limitaciones encontradas.
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Se produjo un error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Para poder generar el borrador es necesario que se ingrese un
                  titulo, una calificación y una captura de pantalla.
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="enter"
                    onClick={handleClose}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                show={showSave}
                onHide={handleCloseSave}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Se produjo un error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  El campo del titulo de la verificacion es obligatorio.
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    className="enter"
                    onClick={handleCloseSave}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>
            </main>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.error,
    monitoreo: state.monitoreo,
    loading: state.loading,
    login: state.login,
    error_monitoreo: state.error_monitoreo,
    monitoreo_id: state.monitoreo_id,
    categorias_form: state.categorias_form,
    cards_monitoreo: state.cards_monitoreo,
    desinformaciones_detalle: state.desinformaciones_detalle,
    busqueada_desinformaciones: state.busqueada_desinformaciones,
    registro_status: state.registro_status,
    nuevo_registro: state.nuevo_registro,
    desinformaciones: state.desinformaciones,
    registro_status_manual: state.registro_status_manual,
    categorias_detalle: state.categorias_detalle,
    page_registrados: state.page_registrados,
    trendings: state.trendings,
    chequeador__verificiones: state.chequeador__verificiones,
    chequeador__verificiones__id: state.chequeador__verificiones__id,
    chequeador__verificiones__new: state.chequeador__verificiones__new,
    chequeador__verificiones__new__save:
      state.chequeador__verificiones__new__save,
    chequeador__verificiones__status: state.chequeador__verificiones__status,
    chequeador__verificiones__is__edit:
      state.chequeador__verificiones__is__edit,
    chequeador__verificiones__borrador:
      state.chequeador__verificiones__borrador,
    chequeador__verificiones__rating: state.chequeador__verificiones__rating,
    chequeador__verificaciones__borrador__data__nuevo:
      state.chequeador__verificaciones__borrador__data__nuevo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVerificaionesID: (id) => dispatch(getVerificaionesID(id)),
    putVerificaionesNueva: (id, items) =>
      dispatch(putVerificaionesNueva(id, items)),
    fetchVerficacionesBorrador: (id, formattedContent) =>
      dispatch(fetchVerficacionesBorrador(id, formattedContent)),
    fetchVerficacionesRating: () => dispatch(fetchVerficacionesRating()),
    resetChequeadorVerificacionesNew: () =>
      dispatch(resetChequeadorVerificacionesNew()),
    fetchVerficacionesHtmlNuevo: (id) =>
      dispatch(fetchVerficacionesHtmlNuevo(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChequeadorFormNew);
