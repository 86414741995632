import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
/* Styled */
import { StyledUsuarios } from "./styled";
import {
  getMessagesFilter,
  getListData,
 getListInvitation,
  getListInvitationDelete,
  getNewUserActive,
  errorInvitation,
} from "src/store";
import Example from "../TableUser";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import LoadingCard from "../../LoadingCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ListadoUsuarios(props) {
  const [active, setActive] = useState("Pedidos");
  const [disabled, setdisabled] = useState(false);
  const [data, setData] = useState();
  const [dataUser, setDataUser] = useState();
  const [loading, setloading] = useState(true);
  const [error, setError] = useState(false);

  const [nameInvitation, setqueryNameInvitation] = useState();
  const [apellidoInvitation, setqueryApellidoInvitation] = useState();
  const [emailInvitation, setqueryEmailInvitation] = useState();
  const [usernameInvitation, setqueryUserNameInvitation] = useState();

  useEffect(() => {
    if (props.user_data) {
      setData(props.user_data);
    }
  }, [props.user_data]);

  useEffect(() => {
    if (props.list_users) {
      setDataUser(props.list_users);
    }
  }, [props.list_users]);

  useEffect(() => {
    try {
      if (props.login) {
        const fetchUser = async () => {
          await props.getListData();
        };
        fetchUser();
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.login]);

  useEffect(() => {
    try {
      if (dataUser && Object.keys(dataUser).length != 0) {
        setloading(false);
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [dataUser]);

  const handleUserForm = (e) => {
    e.preventDefault();
    const mailrepeted = props.list_users
      .map((el) => el.user.email)
      .filter((el) => el == emailInvitation);
    if (mailrepeted.length == 0) {
      props.getListInvitation({
        username: usernameInvitation,
        email: emailInvitation,
        first_name: nameInvitation,
        last_name: apellidoInvitation,
      });
      setqueryNameInvitation("");
      setqueryApellidoInvitation("");
      setqueryEmailInvitation("");
      setqueryUserNameInvitation("");
     
    } else {
      props.errorInvitation(true);
    }
  };

  const handleNameInvitation = (e) => {
    e.preventDefault();
    setqueryNameInvitation(e.target.value);
  };

  const handleApellidoInvitation = (e) => {
    e.preventDefault();
    setqueryApellidoInvitation(e.target.value);
  };

  const handleEmailInvitation = (e) => {
    e.preventDefault();
    setqueryEmailInvitation(e.target.value);
  };

  const handleUserNameInvitation = (e) => {
    e.preventDefault();
    setqueryUserNameInvitation(e.target.value);
  };

  const handleRemoveUser = (id) => {
    props.getListInvitationDelete(id);
  };

  const handleCheckbox = (index) => {
    setDataUser((oldState) => {
      oldState[index].active = !oldState[index].active;
      props.getNewUserActive(oldState[index].id, {
        active: oldState[index].active,
      });
      return [...oldState];
    });
  };

  return (
    <StyledUsuarios>
      {/* {data?.map((el) => (
        <h2>
          <span>{el.user.username}</span>
        </h2>
      ))} */}
      <div className="tablecontainer" style={{ overflow: "scroll", height: "400px", overflowX: "hidden" }}>
        <Table bordered size="sm" style={{backgroundColor: "white"}}>
          <thead style={{position: 'sticky', top: '-2px'}}>
            <tr>
              <th>Nombre</th>
              <th>E-mail</th>
              <th>Rol</th>
              <th>Estado</th>
              <th>Habilitado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              dataUser?.map((el, index) => (
                <tr>
                  <td style={{fontSize: "16px", padding: "10px 0 10px 10px"}}>{el.user.username}</td>
                  <td style={{fontSize: "16px", padding: "10px 0 10px 10px"}}>{el.user.email}</td>
                  <td style={{fontSize: "16px", padding: "10px 0 10px 10px"}}>{el.role.name == "member" ? el.role.name.replace(el.role.name , "Usuario") : el.role.name.replace(el.role.name , "Administrador") }</td>
                  {el.invitation_accepted == false ? (
                    <td style={{fontSize: "16px", padding: "10px 0 10px 10px"}}>Registro pendiente</td>
                  ) : (
                    <td style={{fontSize: "16px", padding: "10px 0 10px 10px"}}>Registro confirmado</td>
                  )}

                  {el.role.name == "member" ? (
                    <>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          aria-label="option 1"
                          checked={el.active ? el.active : false}
                          className="checkbox-user"
                          onClick={() => handleCheckbox(index)}
                          style={{margin: "10px 0 10px 0"}}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <button
                          style={{
                            border: "1px solid white",
                            background: "white",
                          }}
                          onClick={(id) => handleRemoveUser(el.id)}
                        >
                          <FaTrash style={{ color: "#F62D54", fontSize: "16px" }} />
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ textAlign: "center" }}>
                        <input type="checkbox" aria-label="option 1" disabled style={{margin: "10px 0 10px 0"}}/>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <button
                          style={{
                            border: "1px solid white",
                            background: "white",
                          }}
                        >
                          <FaTrash style={{ color: "#ccc", fontSize: "16px" }} />
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <LoadingCard />
            )}
          </tbody>
        </Table>
      </div>
      <Form onSubmit={handleUserForm}>
        <Row>
          <Col lg={12}>
            <p className="title">Agregar nuevo usuario</p>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nombre:</Form.Label>
              <Form.Control
                type="text"
                value={nameInvitation}
                onChange={handleNameInvitation}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nombre de usuario login:</Form.Label>
              <Form.Control
                type="text"
                value={usernameInvitation}
                onChange={handleUserNameInvitation}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Apellido:</Form.Label>
              <Form.Control
                type="text"
                value={apellidoInvitation}
                onChange={handleApellidoInvitation}
                required
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Emai:</Form.Label>
              <Form.Control
                type="email"
                value={emailInvitation}
                onChange={handleEmailInvitation}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            {props.list_users_post_status ? (
              <p
                style={{
                  color: "green",
                }}
              >
                La invitación fue enviada exitósamente
              </p>
            ) : null}

            {props.error_invitation ? (
              <p
                style={{
                  color: "red",
                }}
              >
                Ya existe un usuario con esa dirección de email
              </p>
            ) : null}
          </Col>
          <Col lg={6} style={{textAlign: "right", marginTop: "30px"}}>
            <Button variant="primary" type="submit">
              ENVIAR INVITACIÓN
            </Button>
          </Col>
        </Row>
      </Form>
    </StyledUsuarios>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    token: state.token,
    url: state.url,
    user_data: state.user_data,
    new_pass_status: state.new_pass_status,
    new_data_user_success: state.new_data_user_success,
    list_users: state.list_users,
    list_users_post_status: state.list_users_post_status,
    list_users_post_active: state.list_users_post_active,
    code_new_user: state.code_new_user,
    error_invitation: state.error_invitation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMessagesFilter: (items) => dispatch(getMessagesFilter(items)),
    getListData: () => dispatch(getListData()),
    getListInvitation: (newUser) => dispatch(getListInvitation(newUser)),
    getListInvitationDelete: (id) => dispatch(getListInvitationDelete(id)),
    getNewUserActive: (id, data) => dispatch(getNewUserActive(id, data)),
    errorInvitation: (status) => dispatch(errorInvitation(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListadoUsuarios);
