import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuCard from "src/components/Qsch/MenuCard";
import Card from "src/components/Qsch/Card";
import Filter from "src/components/Qsch/Filter";
import Menu from "src/components/commons/Menu";
import Container from "react-bootstrap/Container";
import { 
  getFilterRequest, 
  getCardsRequestDefault, getPage } from "src/store";
import LoadingCard from "src/components/LoadingCard";
import NoEncontrado from "src/components/no-encontrado";
import { FaArrowRight, FaArrowLeft, FaRegWindowClose } from "react-icons/fa";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
/* Others */
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import Logo from "src/components/Qsch/Logo"
import { StyledBtn, StyledBackground } from "../styled";

function Home(props) {
  const [loading, setloading] = useState(true);

  const changePage = (page) => {
    props.getCardsRequestDefault({
      days: props.days,
      page: page,
      order: props.order,
      media_source: props.media_source.length > 0 ? props.media_source : "all",
      search: props.search,
      category: props.category,
      speaker: props.search_name,
      title: props.search_title,
    });
  };

  const handlePageClick = (data) => {
    const { selected } = data;
    props.getPage(selected + 1);
    changePage(selected + 1);
  };

  const handleResult = (items) => {
    if (items.length > 0) {
      return props.cards.claims.map((el, id) => (
        <Col lg={4} key={id} style={{ marginBottom: "20px" }}>
          <Card
            claims={el.claim}
            id={el.id}
            article={el.article}
            date={el.date}
            speaker={el.speaker}
            start={el.start_position}
            end={el.end_position}
            is_checkable={el.is_checkable}
            is_valid={el.is_valid}
            viralidad_score={el.viralidad_score}
          />
        </Col>
      ));
    } else {
      return <NoEncontrado />;
    }
  };

  useEffect(() => {
    try {
      if (props.login) {
        props.getFilterRequest({
          media_source: props.media_source,
          days: props.days,
          order: props.order,
          page: props.page,
          category: props.category,
          search: props.search,
          speaker: props.search_name,
          title: props.search_title,
        });
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.login]);

  useEffect(() => {
    try {
      if (props.cards && Object.keys(props.cards).length != 0) {
        setloading(false);
      }
    } catch (error) {
      console.log("error al cargar las cards");
    }
  }, [props.cards]);

  if (props.error_filter) {
    return (
      <Modal.Dialog>
        <Modal.Header
          style={{
            justifyContent: "end",
            borderBottom: "1px solid transparent",
          }}
        >
          <Link to="/login">
            <FaRegWindowClose style={{ color: "#000" }} />
          </Link>
        </Modal.Header>

        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <p>Aplicación pendiente de configuración.</p>
            <Nav style={{ justifyContent: "center" }}>
              <StyledBtn>
                <Link to="/user"> Configurar</Link>
              </StyledBtn>
            </Nav>
          </div>
        </Modal.Body>

        <Modal.Footer
          style={{ borderTop: "1px solid transparent" }}
        ></Modal.Footer>
      </Modal.Dialog>
    );
  }
  return (
    <StyledBackground fluid>
      <Row style={{marginBottom: "40px"}}>
        <Col lg={12}>
          <div className="titulo" style={{display: "flex"}}>
            <Logo />
            <p style={{fontSize: "16px", marginBottom: "0"}}>¿Qué se chequea hoy?</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Menu />
      </Row>

      <Row>
        <Col xs={{ span: 12, order: 2 }} lg={{ span: 9, order: 1 }}>
          <MenuCard />
          <Row>
            {!props.loading ? (
              !loading ? (
                handleResult(props.cards.claims)
              ) : null
            ) : (
              <Col lg={12}>
                <LoadingCard />
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={12}>
                  <div key={props.category ? props.category : "all"}>
                    {!props.loading_filter ? (
                      <ReactPaginate
                        previousLabel={<FaArrowLeft />}
                        pageCount={props.cards.total / 12}
                        onPageChange={handlePageClick}
                        nextLabel={<FaArrowRight />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        initialPage={props.page - 1}
                      />
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 12, order: 1 }} lg={{ span: 3, order: 2 }}>
          <Filter />
        </Col>
      </Row>
    </StyledBackground>
  );
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    loading: state.loading,
    login: state.login,
    error: state.error,
    days: state.days,
    media_source: state.media_source,
    order: state.order,
    page: state.page,
    search: state.search,
    category: state.category,
    loading_filter: state.loading_filter,
    search_name: state.search_name,
    search_title: state.search_title,
    list_users: state.list_users,
    error_filter: state.error_filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFilterRequest: (days) => dispatch(getFilterRequest(days)),
    getCardsRequestDefault: (arg) => dispatch(getCardsRequestDefault(arg)),
    getPage: (page) => dispatch(getPage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
