import React, { useState, useEffect } from "react";

/* Redux */
import { connect } from "react-redux";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
/* Estilo */
import { StyledBackground } from "../styled";

/* Bootsrap y MD */
import { Row, Col, Form, Container, Table } from "react-bootstrap";
import { LuUserCircle } from "react-icons/lu";

import { LiaEditSolid } from "react-icons/lia";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiSpreadsheet } from "react-icons/bi";
import { MdClose } from "react-icons/md";

import ReactPaginate from "react-paginate";
/* Components*/
import Logo from "src/components/Chequeador/Logo";

/* Assets */
import not from "src/assets/img/not.png";
import error from "src/assets/img/error.png";
import { chequeadorToggleForm } from "../../store";
import FormSavePublication from "src/components/Chequeador/FormSavePublication";
import styled, { keyframes } from "styled-components";
import { useLocation, useHistory, useParams } from "react-router-dom";
import LoadingCard from "src/components/LoadingCard";
import NoEncontradoVerificion from "src/components/no-encontrado-verificacion";
import {
  getVerificaciones,
  getVerificacionesDelete,
  getVerificaionesID,
  verificacionesIsEdit,
} from "../../store";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

const StyledContainer = styled(Container)`
  .row-container {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .row-container:nth-child(odd) {
    background-color: #f2f5fa;
  }

  .row-container:nth-child(even) {
    background-color: #fff;
  }

  .item-number {
    width: 50px;
    text-align: center;
  }

  .content-column {
    flex: 1;
    padding: 0 15px;
  }

  .text {
    font-size: 14px;
    color: #354052;
    text-decoration: underline;
  }

  .date {
    font-size: 12px;
    text-align: left;
    color: #354052;
    margin-top: 10px;
  }

  .icons-column {
    width: 150px;
    text-align: center;
  }

  .icon {
    margin: 0 8px;
    cursor: pointer;
    font-size: 20px;
    &:hover {
      color: #f72d54;
    }
  }

  .user-column {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-size: 12px;
      font-style: italic;
      color: #354052;
    }
  }

  .user-icon {
    margin-right: 10px;
    font-size: 20px;
    color: #354052;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const DeleteConfirmation = styled.div`
  background: #f72d540d;
  border: 1px solid #f72d54;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  animation: ${slideIn} 0.3s ease-out forwards;
  overflow-y: auto;
  overflow-x: hidden;
  width: 95%;

  .confirmation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    span {
      color: #f72d54;
      font-size: 14px;
      font-weight: bold;
    }
    .close-icon {
      cursor: pointer;
      color: #354052;
    }
  }

  .delete-title p {
    margin: 5px 0;
    font-size: 14px;
    font-style: italic;
    color: #354052;
  }

  .confirmation-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  button {
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .delete-btn {
    background-color: #f72d54;
    color: white;
    border: 1px solid #f72d54;
    border-radius: 20px;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    padding: 0 18px;
    &:hover {
      background-color: transparent;
      color: #f72d54;
      border: 1px solid #f72d54;
    }
  }

  .cancel-btn {
    border: 1px solid #354052;
    border-radius: 20px;
    color: #354052;
    background: 0 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    padding: 0 18px;
    &:hover {
      background-color: #354052;
      color: #fff;
      border: 1px solid #354052;
    }
  }
`;

function HomeChequeador(props) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatorPage, setPaginatorPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const amountOfCardsPerPage = 6;


const initialSelectValues = {
    lastSelectedDays: 168,
    orden: "Todos",
    register: "Todos",
    limit: 10,
    offset: 0,
  };

  






  useEffect(() => {
    props.toggleForm(false, "none", 0);
  }, [props.activeTab]);


const buildApiUrl = (page = 1) => {
  const limit = 6;
  const offset = (page - 1) * limit;
  return { limit, offset };
};

const fetchMonitoreo = async (page = currentPage) => {
  try {
    if (props.login) {
      setLoading(true);
      const { limit, offset } = buildApiUrl(page);
      const response = await props.getVerificaciones({ limit, page: offset });

      
      setTableData(props.chequeador__verificiones.results);

      if (currentPage !== page) {
        setCurrentPage(page);
        setPaginatorPage(page - 1);
      }
    }
  } catch (error) {
    console.log("Error al cargar las cards:", error);
  } finally {
    setLoading(false);
  }
};


 useEffect(() => {
  if (props.chequeador__verificiones) {
    if (currentPage === 1) {
      setTableData(props.chequeador__verificiones.results);
    }
    setCurrentPage(1);
    setPaginatorPage(0);

    const totalPages = Math.ceil(props.chequeador__verificiones.count / 6);
    setHasNextPage(currentPage < totalPages);
  }
}, [props.chequeador__verificiones, props.chequeador__verificiones.next, currentPage]);

  useEffect(() => {
    fetchMonitoreo();
  }, [
    props.login
  ]);

  const handleLoadNewPublication = () => {
    props.toggleForm(true, "save_new_publication", -1);
  };

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirmation(true);
  };


  const handleConfirmDelete = async () => {
  try {
    console.log(`Eliminando item ${itemToDelete}`);
    await props.getVerificacionesDelete(itemToDelete); 
    setShowDeleteConfirmation(false);
    setItemToDelete(null);


    await fetchMonitoreo(currentPage);
  } catch (error) {
    console.error("Error al eliminar la verificación:", error);
  }
};

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setItemToDelete(null);
  };

  const handleEditClick = async (id) => {
    try {
      const data = await props.getVerificaionesID(id);

      history.push(`/chequeador/edit/${id}`);
    } catch (error) {
      console.error("Error loading verification:", error);
    }
  };

  return (
    <StyledBackground fluid={true}>
      <Row>
        <Col lg={12}>
          <div className="titulo">
            <Logo />
            <p>El Chequeador</p>
          </div>
        </Col>
      </Row>
      <Row style={{ paddingLeft: "50px", marginTop: "40px" }}>
        <Col lg={12}>
          <Row>
            <Col lg={7}>
              <Container fluid={true}>
                <Row className="marginBottom:13px">
                  <Col sm={12} md={8}>
                    <h1
                      className="mb-3"
                      style={{ fontSize: "16px", fontWeight: "700", marginTop: "6px" }}
                    >
                      Todas las verificaciones
                    </h1>
                  </Col>
                  <Col sm={12} md={4}>
                    <button
                      className="button-cargar"
                      onClick={() => handleLoadNewPublication()}
                    >
                      Crear nueva verificación
                    </button>
                  </Col>
                </Row>
                <Row>
                  <StyledContainer>
                    {tableData == null || tableData == undefined || loading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <LoadingCard />
                      </div>
                    ) : tableData !== undefined &&
                      tableData !== null &&
                      tableData.length > 0 ? (
                      tableData?.map((item, index) => (
                        <div key={item.id} className="row-container">
                          <div className="item-number">{index + 1}</div>
                          <div className="content-column">
                            <div className="text">{item.title}</div>
                            <div className="date">
                              {dayjs(item.date).format("D-MM-YYYY")}
                            </div>
                          </div>
                          <div className="icons-column">
                            <LiaEditSolid
                              className="icon"
                              onClick={() => handleEditClick(item.id)}
                              style={{ cursor: "pointer" }}
                            />
                            <RiDeleteBin6Line
                              className="icon"
                              onClick={() => handleDeleteClick(item.id)}
                            />
                          {/* Si googleDocUrl existe, el ícono será clickeable y abrirá en nueva pestaña */}
{item.googleDocUrl ? (
  <BiSpreadsheet 
    className="icon" 
    style={{ cursor: "pointer" }} 
    onClick={() => window.open(item.googleDocUrl, '_blank')}
  />
) : (
  <BiSpreadsheet 
    className="icon" 
    style={{ color: "#C6C6C6", cursor: "inherit" }} 
  />
)}
                          </div>
                          <div className="user-column">
                            <LuUserCircle className="user-icon"/>
                            <span>{item.userName}</span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <NoEncontradoVerificion />
                    )}
                  </StyledContainer>
                </Row>
              </Container>
            </Col>
            <Col md={5}>
              <FormSavePublication
                id="save_new_publication"
                cardTitle="Crear nueva verificación"
              />
              {showDeleteConfirmation && (
                <DeleteConfirmation className="container">
                  <div className="confirmation-header">
                    <span>
                      <RiDeleteBin6Line
                        className="icon"
                        style={{ fontSize: "22px" }}
                      />{" "}
                      ¿Desea eliminar la verificación?
                    </span>
                    <MdClose
                      className="close-icon"
                      onClick={handleCancelDelete}
                    />
                  </div>
                  <div className="delete-title">
                    <p>
                      {
                        tableData.find((item) => item.id === itemToDelete)
                          ?.title
                      }
                    </p>
                  </div>
                  <div className="confirmation-buttons">
                    <button
                      className="delete-btn"
                      onClick={() => handleConfirmDelete(itemToDelete)}
                    >
                      Eliminar
                    </button>
                    <button className="cancel-btn" onClick={handleCancelDelete}>
                      No eliminar
                    </button>
                  </div>
                </DeleteConfirmation>
              )}
            </Col>
            
 {tableData !== undefined &&
  tableData !== null &&
  tableData.length > 0 ? (
  <ReactPaginate
    previousLabel={<FaArrowLeft />}
    nextLabel={<FaArrowRight />}
    pageCount={Math.ceil(props.chequeador__verificiones.count / 6)}
    onPageChange={({ selected }) => {
      const page = selected + 1;
      fetchMonitoreo(page);
      window.scrollTo(0, 0);
    }}
    breakLabel={"..."}
    breakClassName={"break-me"}
    marginPagesDisplayed={2}
    pageRangeDisplayed={5}
    containerClassName={"pagination"}
    activeClassName={"active"}
    initialPage={currentPage - 1}
    forcePage={paginatorPage}
  />
) : null}
          </Row>
        </Col>
      </Row>
    </StyledBackground>
  );
}

const mapStateToProps = (state) => {
  return {
    error: state.error,
    monitoreo: state.monitoreo,
    loading: state.loading,
    login: state.login,
    error_monitoreo: state.error_monitoreo,
    monitoreo_id: state.monitoreo_id,
    categorias_form: state.categorias_form,
    cards_monitoreo: state.cards_monitoreo,
    desinformaciones_detalle: state.desinformaciones_detalle,
    busqueada_desinformaciones: state.busqueada_desinformaciones,
    registro_status: state.registro_status,
    nuevo_registro: state.nuevo_registro,
    desinformaciones: state.desinformaciones,
    registro_status_manual: state.registro_status_manual,
    categorias_detalle: state.categorias_detalle,
    page_registrados: state.page_registrados,
    trendings: state.trendings,
    chequeador__verificiones: state.chequeador__verificiones,
    chequeador__verificiones__is__edit:
      state.chequeador__verificiones__is__edit,
    chequeador__verificaciones__page: state.chequeador__verificaciones__page,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleForm: (openForm, openFormId, publication_id) =>
      dispatch(chequeadorToggleForm(openForm, openFormId, publication_id)),
    getVerificaciones: (data) => dispatch(getVerificaciones(data)),
    getVerificacionesDelete: (id) => dispatch(getVerificacionesDelete(id)),
    getVerificaionesID: (id) => dispatch(getVerificaionesID(id)),
    verificacionesIsEdit: () => dispatch(verificacionesIsEdit),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeChequeador);
